import React, {useEffect, useRef, useState} from 'react';
import classnames from 'classnames';
import {useNavigate} from 'react-router-dom';
import {DicomMetadataStore, MODULE_TYPES} from '@ohif/core';

import Dropzone from 'react-dropzone';
import filesToStudies from './filesToStudies';

import {extensionManager, servicesManager} from '../../App.tsx';

import {Icon, Button, LoadingIndicatorProgress} from '@ohif/ui';
import './File.css'; // 引入你的样式文件

const getLoadButton = (onDrop, text, isDir) => {
    return (
        <Dropzone onDrop={onDrop} noDrag>
            {({getRootProps, getInputProps}) => (
                <div {...getRootProps()}>
                    <Button
                        rounded="full"
                        variant="contained" // outlined
                        disabled={false}
                        endIcon={<Icon name="launch-arrow"/>} // launch-arrow | launch-info
                        className={classnames('font-medium', 'ml-2')}
                        style={{backgroundColor: 'rgb(149 145 145)'}}
                        onClick={() => {
                        }}
                    >
                        {text}
                        {isDir ? (
                            <input
                                {...getInputProps()}
                                webkitdirectory="true"
                                mozdirectory="true"
                            />
                        ) : (
                            <input {...getInputProps()} />
                        )}
                    </Button>
                </div>
            )}
        </Dropzone>
    );
};

type LocalProps = {
    modePath: string;
};

// @ts-ignore
function Flie({modePath}: LocalProps) {
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    let requestOptions = {};


    const StudyInstanceUIDs = urlParams.get('StudyInstanceUIDs') != undefined ? urlParams.get('StudyInstanceUIDs') : '1.2.840.113619.2.55.3.3301181124.435.1677227341.950';
    const {
        uiNotificationService,
    } = servicesManager.services;

    // Initializing the dicom local dataSource
    const dataSourceModules = extensionManager.modules[MODULE_TYPES.DATA_SOURCE];
    const localDataSources = dataSourceModules.reduce((acc, curr) => {
        const mods = [];
        curr.module.forEach(mod => {
            if (mod.type === 'localApi') {
                mods.push(mod);
            }
        });
        return acc.concat(mods);
    }, []);

    const firstLocalDataSource = localDataSources[0];
    const dataSource = firstLocalDataSource.createDataSource({});

    const microscopyExtensionLoaded = extensionManager.registeredExtensionIds.includes(
        '@ohif/extension-dicom-microscopy'
    );

    function fetchFileFromUrl(url, fileName) {
        return fetch(url,requestOptions)
            .then(response => response.blob())
            .then(blob => new File([blob], fileName));
    }

    function getUIDsFromArray(dataArray) {
        const uids = [];

        // 遍历 DICOM 数据数组
        dataArray.forEach(data => {
            let studies = ''
            let series = ''
            // 遍历 DICOM 数据的键
            Object.keys(data).forEach(key => {
                const studiesregex = /000D$/; // 这里的$表示匹配结尾
                const seriesregex = /000E$/; // 这里的$表示匹配结尾
                const isstudies = studiesregex.test(key);
                const isseries = seriesregex.test(key);

                if (isstudies && data[key].vr == 'UI') {
                    studies = data[key].Value[0]
                }
                if (isseries && data[key].vr == 'UI') {
                    series = data[key].Value[0]
                }
            });
            const instancesUrl = window.config.httpCloudUrl + 'rs/studies/' + studies + '/series/' + series + '/instances?includefield=all&offset=0&orderby=InstanceNumber'
            uids.push(instancesUrl)
        });
        return uids;
    }

    function wadoLoadArray(dataArray) {
        const uids = [];

        // 遍历 DICOM 数据数组
        dataArray.forEach(data => {
            let studies = ''
            let series = ''
            let object = ''
            // 遍历 DICOM 数据的键
            Object.keys(data).forEach(key => {
                const studiesregex = /000D$/; // 这里的$表示匹配结尾
                const seriesregex = /000E$/; // 这里的$表示匹配结尾
                const objectregex = /0018$/; // 这里的$表示匹配结尾
                const isstudies = studiesregex.test(key);
                const isseries = seriesregex.test(key);
                const isobject = objectregex.test(key);

                if (isstudies && data[key].vr == 'UI') {
                    studies = data[key].Value[0]
                }
                if (isseries && data[key].vr == 'UI') {
                    series = data[key].Value[0]
                }
                if (isobject && data[key].vr == 'UI') {
                    object = data[key].Value[0]
                }
            });
            const instancesUrl = window.config.httpCloudUrl + 'wado?requestType=WADO&studyUID=' + studies + '&seriesUID=' + series + '&objectUID=' + object + '&contentType=application/dicom'
            uids.push(instancesUrl)
        });
        return uids;
    }

    const onDrop = async acceptedFiles => {


        if (window.config.tokenFlag){
            await fetch(window.config.getTokenUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(window.config.requestData).toString()
            })
                .then(response => response.json())
                .then(data => {
                    if (data&&data.access_token){
                        window.token = {'Authorization': `Bearer ${data.access_token}`}
                    }else{
                        window.token = {}
                    }
                })
                .catch(error => {
                    window.token = {}
                    console.error('发生错误:', error);
                });
        }else{
            window.token = {}
        }

        requestOptions = {
            method: 'GET',
            headers: window.token
        };

        let xl = []
        let xlUrl = window.config.httpCloudUrl + 'rs/studies/' + StudyInstanceUIDs + '/series?includefield=all&offset=0&orderby=SeriesNumber'
        await fetch(xlUrl,requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // 将响应数据解析为 JSON
            })
            .then(data => {
                // 在这里处理从服务器返回的数据
                console.log(data);
                xl = data
            })
            .catch(error => {
                console.error('Error:', error);
            });

        const uids = getUIDsFromArray(xl);

        let allWadoUrl = []

        for (let i = 0; i < uids.length; i++) {
            await fetch(uids[i],requestOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json(); // 将响应数据解析为 JSON
                })
                .then(data => {
                    // 在这里处理从服务器返回的数据
                    console.log(data);
                    const urlData = wadoLoadArray(data)
                    allWadoUrl = [...allWadoUrl, ...urlData]
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }


        // 获取目标div元素
        const targetDiv = document.querySelector('.bg-black');
        const overlay = document.createElement('div');
        overlay.classList.add('overlay');
        overlay.innerHTML =
            "<div class='divlight'><div class='spanTitlelight'>正在读取图像，共" + allWadoUrl.length + "张，目前已读取<span class='highlight'>" +
            0 +
            "</span>张，请等待...</div><div class='spanTextlight'>从服务器读取图像的速度取决于您当前的网络及电脑性能,请耐心等待。在读取的过程中，请勿关闭当前页面!</div></div>"; // 提示文字

        targetDiv.appendChild(overlay);





        let list = []

        for (const File of allWadoUrl) {
            let index = allWadoUrl.indexOf(File);
            overlay.innerHTML =
                "<div class='divlight'><div class='spanTitlelight'>正在读取图像，共" + allWadoUrl.length + "张，目前已读取<span class='highlight'>" +
                index +
                "</span>张，请等待...</div><div class='spanTextlight'>从服务器读取图像的速度取决于您当前的网络及电脑性能,请耐心等待。在读取的过程中，请勿关闭当前页面!</div></div>"; // 提示文字
            await fetchFileFromUrl(allWadoUrl[index], index + '.dcm')
                .then(file => {
                    // 这里的 file 就是一个可以被 new FileLoaderService(file) 接收的 File 对象
                    // 使用 new FileLoaderService(file) 进行相应操作
                    list.push(file)
                })
                .catch(error => console.error('Error:', error));
        }


        const studies = await filesToStudies(list, dataSource);

        if (studies == undefined || studies.length == 0) {
            uiNotificationService.show({
                title: '查询失败',
                message: '未查询到相关图像数据',
                type: 'error',
                duration: 3000,
            });
            overlay.style.display = 'none';
            return;
        } else {
            uiNotificationService.show({
                title: '成功',
                message: '图像已全部读取完成',
                type: 'success',
                duration: 3000,
            });
        }
        setTimeout(() => {
            overlay.style.display = 'none';
        }, 1000);

        const query = new URLSearchParams();

        if (microscopyExtensionLoaded) {
            // TODO: for microscopy, we are forcing microscopy mode, which is not ideal.
            //     we should make the local drag and drop navigate to the worklist and
            //     there user can select microscopy mode
            const smStudies = studies.filter(id => {
                const study = DicomMetadataStore.getStudy(id);
                return (
                    study.series.findIndex(
                        s => s.Modality === 'SM' || s.instances[0].Modality === 'SM'
                    ) >= 0
                );
            });

            if (smStudies.length > 0) {
                smStudies.forEach(id => query.append('StudyInstanceUIDs', id));

                modePath = 'microscopy';
            }
        }

        // Todo: navigate to work list and let user select a mode
        studies.forEach(id => query.append('StudyInstanceUIDs', id));
        query.append('datasources', 'dicomlocal');
        query.append('StudyInstanceUIDs', studies[0]);

        navigate(`/${modePath}?${decodeURIComponent(query.toString())}`);
    };





    const onDw = async acceptedFiles => {
        requestOptions = {
            method: 'GET',
            headers: {'Access-Control-Allow-Origin':'http://localhost:8081','Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE','Access-Control-Allow-Headers':'Content-Type'}
        };

        let xl = []
        let xlUrl = window.config.findReomteDcmQueryUrl + StudyInstanceUIDs
        await fetch(xlUrl,requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // 将响应数据解析为 JSON
            })
            .then(data => {
                // 在这里处理从服务器返回的数据
                console.log(data);
                xl = data
            })
            .catch(error => {
                console.error('Error:', error);
            });
        console.log(xl)

        let allWadoUrl = []
        xl.data.forEach(item => {
            allWadoUrl.push(window.config.findReomteDcmLoadUrl + item.imagepath.split('\\images\\')[1])
        })

        // 获取目标div元素
        const targetDiv = document.querySelector('.bg-black');
        const overlay = document.createElement('div');
        overlay.classList.add('overlay');
        overlay.innerHTML =
            "<div class='divlight'><div class='spanTitlelight'>正在读取图像，共" + allWadoUrl.length + "张，目前已读取<span class='highlight'>" +
            0 +
            "</span>张，请等待...</div><div class='spanTextlight'>从服务器读取图像的速度取决于您当前的网络及电脑性能,请耐心等待。在读取的过程中，请勿关闭当前页面!</div></div>"; // 提示文字

        targetDiv.appendChild(overlay);



        let list = []

        for (const File of allWadoUrl) {
            let index = allWadoUrl.indexOf(File);
            overlay.innerHTML =
                "<div class='divlight'><div class='spanTitlelight'>正在读取图像，共" + allWadoUrl.length + "张，目前已读取<span class='highlight'>" +
                index +
                "</span>张，请等待...</div><div class='spanTextlight'>从服务器读取图像的速度取决于您当前的网络及电脑性能,请耐心等待。在读取的过程中，请勿关闭当前页面!</div></div>"; // 提示文字
            await fetchFileFromUrl(allWadoUrl[index], index + '.dcm')
                .then(file => {
                    // 这里的 file 就是一个可以被 new FileLoaderService(file) 接收的 File 对象
                    // 使用 new FileLoaderService(file) 进行相应操作
                    list.push(file)
                })
                .catch(error => console.error('Error:', error));
        }


        const studies = await filesToStudies(list, dataSource);

        if (studies == undefined || studies.length == 0) {
            uiNotificationService.show({
                title: '查询失败',
                message: '未查询到相关图像数据',
                type: 'error',
                duration: 3000,
            });
            overlay.style.display = 'none';
            return;
        } else {
            uiNotificationService.show({
                title: '成功',
                message: '图像已全部读取完成',
                type: 'success',
                duration: 3000,
            });
        }
        setTimeout(() => {
            overlay.style.display = 'none';
        }, 1000);

        const query = new URLSearchParams();

        if (microscopyExtensionLoaded) {
            // TODO: for microscopy, we are forcing microscopy mode, which is not ideal.
            //     we should make the local drag and drop navigate to the worklist and
            //     there user can select microscopy mode
            const smStudies = studies.filter(id => {
                const study = DicomMetadataStore.getStudy(id);
                return (
                    study.series.findIndex(
                        s => s.Modality === 'SM' || s.instances[0].Modality === 'SM'
                    ) >= 0
                );
            });

            if (smStudies.length > 0) {
                smStudies.forEach(id => query.append('StudyInstanceUIDs', id));

                modePath = 'microscopy';
            }
        }

        // Todo: navigate to work list and let user select a mode
        studies.forEach(id => query.append('StudyInstanceUIDs', id));
        query.append('datasources', 'dicomlocal');
        query.append('StudyInstanceUIDs', studies[0]);

        navigate(`/${modePath}?${decodeURIComponent(query.toString())}`);
    };



    if (window.config.findReomteDcmLoadUrl == 'https://192.168.90.137:13001/'){
        onDw(null).then(r => {})
    }else if(urlParams.get('StudyInstanceUIDs') != undefined){
        onDrop(null).then(r => {})
    }



    // Set body style
    useEffect(() => {
        document.body.classList.add('bg-black');
        return () => {
            document.body.classList.remove('bg-black');
        };
    }, []);
    return (
        <div className="desktop">

        </div>
    );
}

export default Flie;
