import {
    CrosshairsTool,
} from '@cornerstonejs/tools';
import {Enums} from "@cornerstonejs/core";

function defaultReferenceLineColor() {
    return 'rgb(0, 200, 0)';
}

class CrosshairsNewTool extends CrosshairsTool {
    constructor(toolProps, defaultToolProps) {
        toolProps.configuration = {
            getReferenceLineColor: defaultReferenceLineColor,
            supportedInteractionTypes: ['Mouse'],
            shadow: true,
            viewportIndicators: true,
            autoPan: {
                enabled: true,
                panSize:10,
            },
            referenceLinesCenterGapRadius: 10,
            filterActorUIDsToSetSlabThickness: [],
            slabThicknessBlendMode: window.config.slabThicknessBlendMode,
            mobile: {
                enabled: true,
                opacity: 4,
                handleRadius: 4,
            }
        }
        super(toolProps, defaultToolProps);
    }
}

CrosshairsNewTool.toolName = 'Crosshairs';
export default CrosshairsNewTool;
