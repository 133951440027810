/**
 * Entry point for development and production PWA builds.
 */
import 'regenerator-runtime/runtime';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import { history } from './utils/history';

/**
 * EXTENSIONS AND MODES
 * =================
 * pluginImports.js is dynamically generated from extension and mode
 * configuration at build time.
 *
 * pluginImports.js imports all of the modes and extensions and adds them
 * to the window for processing.
 */
import {
  modes as defaultModes,
  extensions as defaultExtensions,
} from './pluginImports';
import loadDynamicConfig from './loadDynamicConfig';

loadDynamicConfig(window.config).then(config_json => {
  // Reset Dynamic config if defined
  if (config_json !== null) {
    window.config = config_json;
  }
  //播放配置
  window.allStartPlayer = false
  window.isPlayType = 'only'
  window.cines = {}
  window.windowLevel = false
  window.markingFlag = true
  window.windowLevelData = {lower:500, upper:50}
  window.canvas = null
  window.fusionIamge = false
  window.displaySets = []
  window.tabData = null
  window.elements = []
  /**
   * Combine our appConfiguration with installed extensions and modes.
   * In the future appConfiguration may contain modes added at runtime.
   *  */
  const appProps = {
    config: window ? window.config : {},
    defaultExtensions,
    defaultModes,
  };

  /** Create App */
  const app = React.createElement(App, appProps, null);
  /** Render */
  ReactDOM.render(app, document.getElementById('root'));
});

export { history };
