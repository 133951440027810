import React, { useEffect, useState, useContext } from 'react';
import { useViewportGrid, Player, useCine } from '@ohif/ui';
import { useTranslation } from 'react-i18next';
import { useQuery, useSearchParams } from '@hooks';
import 'regenerator-runtime/runtime';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import {
  imageLoader,
  getEnabledElements,
  default as cornerstone,
  cache,
} from '@cornerstonejs/core';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Radio, Select, RadioChangeEvent, Spin, Alert } from 'antd';
import { getActiveViewportEnabledElement } from '../../../cornerstone/src';
import tsWhammy from 'ts-whammy';
import './Toolbar.css'; // 引入你的样式文件
import findViewportsByPosition, {
  findOrCreateViewport as layoutFindOrCreate,
} from '../findViewportsByPosition';
import * as cornerstoneTools from '@cornerstonejs/tools';
import translation from '../../../../platform/i18n/src/locales/zh/Buttons.json';
import { notification } from 'antd';
// 导入 Cornerstone 和相关模块
let iframe;
export default function Toolbar({ servicesManager }) {
  const {
    toolbarService,
    hangingProtocolService,
    displaySetService,
    stateSyncService,
    toolGroupService,
    uiNotificationService,
    cornerstoneViewportService,
  } = servicesManager.services;
  const navigate = useNavigate();
  const [toolbarButtons, setToolbarButtons] = useState([]);
  const [show, setShow] = useState(false);
  const [buttonState, setButtonState] = useState({
    primaryToolId: '',
    toggles: {},
    groups: {},
  });
  const { t } = useTranslation('Buttons');
  const [viewportGrid, viewportGridService] = useViewportGrid();
  const { layout, activeViewportIndex, viewports } = viewportGrid;
  const searchParams = useSearchParams();
  const urlId = searchParams.get('studyinstanceuids');
  const runTimeHangingProtocolId = searchParams.get('hangingprotocolid');
  const [modalLoading, setModalLoading] = useState(false);

  function react() {
    let ExitButtonArgs = {
      interactionType: 'tool',
      itemId: 'ExitButton',
      commands: [
        {
          commandName: 'setToolActive',
          commandOptions: { toolName: 'ExitButton' },
          context: 'CORNERSTONE',
          toggledState: true,
        },
      ],
    };
    toolbarService.recordInteraction(ExitButtonArgs);
  }

  const onInteraction = args => {
    //开启自动选中
    if (
      args.itemId == 'Length' ||
      args.itemId == 'Bidirectional' ||
      args.itemId == 'Annotation' ||
      args.itemId == 'Ellipse' ||
      args.itemId == 'Circle' ||
      args.itemId == 'Rectangle' ||
      args.itemId == 'Angle' ||
      args.itemId == 'CobbAngle' ||
      args.itemId == 'Sharpening' ||
      args.itemId == 'Probe' ||
      args.itemId == 'Freehandline' ||
      args.itemId == 'LengthCTR'
    ) {
      window.group = true;
    } else {
      window.group = false;
    }
    //先做个简单判断，如果在3D页面点进来了，不能用的功能给个提醒return出去
    if (runTimeHangingProtocolId) {
      if (
        args.itemId === 'StackScroll' ||
        args.itemId === 'FreeRotation' ||
        args.itemId === 'rotate-right' ||
        args.itemId === 'flip-horizontal' ||
        args.itemId === 'invert' ||
        args.itemId === 'Magnify' ||
        (args.groupId == 'UploadAndDownload' && args.itemId === '2') ||
        (args.groupId == 'UploadAndDownload' && args.itemId === '3')
      ) {
        uiNotificationService.show({
          title: '使用工具失败',
          message: '在3D模式下，暂不支持该功能，您可退出3D模式后再进行使用。',
          type: 'error',
          duration: 4000,
        });
        return;
      }
    }
    if (args.itemId === 'Zoom' || args.itemId === 'Pan') {
      const enabledElement =
        getActiveViewportEnabledElement(viewportGridService);
      if (enabledElement.viewport.type == 'volume3d') {
        uiNotificationService.show({
          title: '切换失败',
          message: '请勿在选中3D模型时切换常用工具或试图编辑3D模型。',
          type: 'error',
          duration: 4000,
        });
        return;
      }
    }
    if (args.itemId === 'Capture') {
      const enabledElement =
        getActiveViewportEnabledElement(viewportGridService);
      if (enabledElement.viewport.type == 'volume3d') {
        uiNotificationService.show({
          title: '拍照失败',
          message: '3D图像不支持拍照功能，您可选择其他图像进行拍照。',
          type: 'error',
          duration: 4000,
        });
        return;
      }
    }
    if (args.itemId === 'ShowText') {
      setShow(!show);
      toolbarService.setButtonType(args, !show);
    }
    if (args.itemId === 'ExitFusionIamge') {
      // react()
      let ExitButtonArgs = {
        interactionType: 'tool',
        itemId: 'ExitButton',
        commands: [
          {
            commandName: 'setToolActive',
            commandOptions: { toolName: 'ExitButton' },
            context: 'CORNERSTONE',
            toggledState: true,
          },
        ],
      };
      toolbarService.recordInteraction(ExitButtonArgs);

      if (window.location.href.indexOf('dicomlocal?') > -1) {
        setTimeout(() => {
          navigate({
            pathname: '/viewer/dicomlocal',
            search:
              `?StudyInstanceUIDs=` +
              window.studyinstanceuids +
              (window.layoutFlag ? '&layout=topAndButtom' : ''),
          });
        }, 1 * 1000);
        return;
      }

      const ifr = window.parent.document.getElementById('tmtv');
      if (ifr) {
        window.config.tmtvFlag = true;
        ifr.style.zIndex = '-2';
        // const parentElement = ifr.parentNode;
        // parentElement.removeChild(ifr);
      }
      const iframe = window.document.getElementById('tmtv');
      if (iframe) {
        window.config.tmtvFlag = true;
        iframe.style.zIndex = '-2';
      }
    }
    if (args.itemId === 'FusionIamge') {
      const displaySets = displaySetService.activeDisplaySets;
      console.log(displaySets);
      let CT = 0;
      let PT = 0;
      const CTarr = [];
      const PTarr = [];
      displaySets.forEach(displaySet => {
        if (displaySet.Modality == 'CT') {
          CT++;
          CTarr.push(displaySet);
        }
        if (displaySet.Modality == 'PT') {
          PT++;
          PTarr.push(displaySet);
        }
      });
      const ctptArr = [];
      for (const ptObj of PTarr) {
        for (const ctObj of CTarr) {
          // 检查两个对象的images属性是否有相同的值
          if (ctObj.images.length == ptObj.images.length) {
            ctptArr.push({
              ctObj: ctObj,
              ptObj: ptObj,
            });
          }
        }
      }
      if (CT != 1 || PT != 1) {
        uiNotificationService.show({
          title: '融合影像失败',
          message:
            '未检测到两个可以融合的序列。（同部位的PT和CT且序列数一致）。',
          type: 'error',
          duration: 6000,
        });
        return;
      } else if (ctptArr.length == 0) {
        uiNotificationService.show({
          title: '融合影像失败',
          message:
            '未检测到两个可以融合的序列。（同部位的PT和CT且序列数一致）。',
          type: 'error',
          duration: 6000,
        });
        return;
      } else {
        react();
        if (window.location.href.indexOf('dicomlocal?') > -1) {
          navigate({
            pathname: '/tmtv/dicomlocal',
            search:
              `?StudyInstanceUIDs=` +
              window.studyinstanceuids +
              (window.layoutFlag ? '&layout=topAndButtom' : ''),
          });
          return;
        }

        let num = Math.ceil(ctptArr[0].ctObj.images.length / 50) + 2;
        // 获取目标div元素
        const targetDiv = document.querySelector('.bg-black');

        const overlay = document.createElement('div');

        overlay.classList.add('overlay');

        let ifr = window.parent.document.getElementById('tmtv');
        if (ifr) {
          ifr.style.zIndex = '99';
          return;
        }

        ifr = window.document.getElementById('tmtv');
        if (ifr) {
          ifr.style.zIndex = '99';
          return;
        }

        overlay.innerHTML =
          "<div class='divlight'><div class='spanTitlelight'>正在渲染融合页面，预计还需<span class='highlight'>" +
          num +
          "</span>秒，请等待...</div><div class='spanTextlight'>在浏览器内进行3维重建是一项比较费时费力的任务,有损压缩尤其费时,请耐心等待。尤其建议请在性能较好的设备上进行3维重建!</div></div>"; // 提示文字

        targetDiv.appendChild(overlay);

        const timer = setInterval(() => {
          num--;
          overlay.innerHTML =
            "<div class='divlight'><div class='spanTitlelight'>正在渲染融合页面，预计还需<span class='highlight'>" +
            num +
            "</span>秒，请等待...</div><div class='spanTextlight'>在浏览器内进行3维重建是一项比较费时费力的任务,有损压缩尤其费时,请耐心等待。尤其建议请在性能较好的设备上进行3维重建!</div></div>"; // 提示文字
          if (num == 0) {
            clearInterval(timer);
          }
        }, 1000);

        // 获取要渲染内容的URL
        let url = '/tmtv?StudyInstanceUIDs=' + urlId;
        if (window.layoutFlag) {
          url = url + '&layout=topAndButtom';
        }
        iframe = document.createElement('iframe');
        iframe.src = url;
        iframe.id = 'tmtv';
        // 设置 iframe 的基本样式
        iframe.style.width = '100%'; // 设置宽度为 100%
        iframe.style.height = '100vh'; // 设置高度为视口高度
        iframe.style.border = 'none'; // 移除边框
        iframe.style.position = 'absolute';
        iframe.style.top = '0';
        iframe.style.zIndex = '-1';
        targetDiv.appendChild(iframe);
        // 监听 iframe 的加载事件，一旦加载完成，隐藏遮罩层
        iframe.addEventListener('load', () => {
          setTimeout(
            () => {
              iframe.style.zIndex = '99';
              overlay.style.display = 'none'; // 隐藏遮罩层
            },
            (num - 1) * 1000
          );
        });
      }
      return;
    }
    if (args.itemId === 'DownloadImageZip') {
      let value = 'jpg';
      const handleChange = e => {
        value = e.target.value;
      };
      const openModal = () => {
        const modal = Modal.confirm({
          title: '批量下载该序列所有图像',
          content: (
            <div style={{ marginTop: '20px', height: '250vh' }}>
              <p
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  fontSize: '15px',
                  fontWeight: '600',
                }}
              >
                选择图片下载格式
              </p>
              <Radio.Group
                name="radiogroup"
                defaultValue={value}
                onChange={handleChange}
              >
                <Radio value={'jpg'}>下载JPG格式图像</Radio>
                <Radio value={'png'}>下载PNG格式图像</Radio>
                <Radio value={'jpeg'}>下载JPEG格式图像</Radio>
                <Radio value={'dcm'}>下载DCM格式图像</Radio>
              </Radio.Group>
            </div>
          ),
          closable: true,
          okText: '确定下载', // 更改确定按钮的文本
          cancelText: '取消下载', // 更改确定按钮的文本
          icon: null, // 禁用默认图标
          width: '40%', // 自定义宽度
          okButtonProps: {
            className: 'custom-ok-button', // 自定义类名
            style: { background: 'blue', borderColor: 'blue' }, // 自定义样式
            loading: modalLoading, // 开启加载状态
          },
          onOk() {
            setModalLoading(true);
            // get actor from the viewport
            const enabledElement =
              getActiveViewportEnabledElement(viewportGridService);
            const viewport = enabledElement.viewport;
            // 创建一个用于存储 canvas 的数组
            const imageIds = viewport.imageIds;

            const secondsLeft = Math.ceil(imageIds.length / 10);
            const message = `下载当前序列的全部图像中,预计需要${secondsLeft}秒,请等待....`;
            uiNotificationService.show({
              title: '下载图片',
              message: message,
              type: 'info',
              duration: secondsLeft * 1000, // 通知持续1秒
            });
            // 创建一个新的压缩包
            const zip = new JSZip();

            const num = imageIds.length;
            // 遍历 imageIds 并将每个图像添加到 canvases 数组中
            imageIds.forEach((imageId, index) => {
              imageLoader.loadAndCacheImage(imageId).then(image => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = image.width;
                canvas.height = image.height;
                const imageData = ctx.createImageData(
                  image.width,
                  image.height
                );
                const pixelData = image.getPixelData();

                for (let i = 0; i < pixelData.length; i++) {
                  // 由于 pixelData 是单通道的灰度图像，所以 R、G、B 都使用相同的值
                  imageData.data[i * 4] = pixelData[i];
                  imageData.data[i * 4 + 1] = pixelData[i];
                  imageData.data[i * 4 + 2] = pixelData[i];
                  imageData.data[i * 4 + 3] = 255; // 设置透明度为不透明
                }
                ctx.putImageData(imageData, 0, 0);
                if (value != 'dcm') {
                  const imgData = canvas.toDataURL('image/' + value);
                  zip.file(
                    `image_${image.imageId.split('/')[12]}.${value}`,
                    imgData.split(',')[1],
                    { base64: true }
                  );
                  if (num - 1 == index) {
                    zip.generateAsync({ type: 'blob' }).then(content => {
                      // 下载压缩包
                      FileSaver.saveAs(content, 'myZipFile.zip');
                      setModalLoading(false);
                    });
                  }
                } else {
                  const imageUrl = image.imageId.split('dicomweb')[1];
                  const parts = imageUrl.split('/');
                  const studyUID = parts[2];
                  const seriesUID = parts[4];
                  const objectUID = parts[6];
                  const DOWNLOAD_URL = window.config.DOWNLOAD_URL;
                  const hrefUrl =
                    DOWNLOAD_URL +
                    '&studyUID=' +
                    studyUID +
                    '&seriesUID=' +
                    seriesUID +
                    '&objectUID=' +
                    objectUID +
                    '&contentType=application/dicom';
                  const xhr = new XMLHttpRequest();
                  xhr.open('GET', hrefUrl, true);
                  xhr.responseType = 'blob';
                  xhr.onload = function () {
                    const blob = new Blob([xhr.response], {
                      type: 'application/octet-stream',
                    });
                    const reader = new FileReader();
                    reader.onload = function () {
                      const base64String = reader.result.split(',')[1];
                      zip.file(
                        `image_${image.imageId.split('/')[12]}.${value}`,
                        base64String,
                        { base64: true }
                      );
                      if (num - 1 == index) {
                        zip.generateAsync({ type: 'blob' }).then(content => {
                          // 下载压缩包
                          FileSaver.saveAs(content, 'myZipFile.zip');
                          setModalLoading(false);
                        });
                      }
                    };
                    reader.readAsDataURL(blob);
                  };
                  xhr.send();
                }
              });
            });
          },
        });
      };
      openModal();
    }
    if (args.itemId === 'DownloadVideo') {
      let value = 'webm';
      // get actor from the viewport
      const enabledElement =
        getActiveViewportEnabledElement(viewportGridService);
      // const renderingEngine = cornerstoneViewportService.getRenderingEngine();
      const viewport = enabledElement.viewport;
      const imageIds = viewport.imageIds;
      if (imageIds.length < 10) {
        uiNotificationService.show({
          title: '生成失败',
          message: '无法生成少于十张影像的视频',
          type: 'error',
          duration: 3000,
        });
        return;
      }
      const handleChange = e => {
        value = e.target.value;
      };
      const openModal = () => {
        Modal.confirm({
          title: '将当前序列图片转换为视频',
          content: (
            <div style={{ marginTop: '20px', height: '25vh' }}>
              <p
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  fontSize: '15px',
                  fontWeight: '600',
                }}
              >
                选择视频下载格式
              </p>
              <Radio.Group
                name="radiogroup"
                defaultValue={value}
                onChange={handleChange}
              >
                <Radio value={'webm'}>导出WEBM格式视频</Radio>
                <Radio value={'mp4'}>导出MP4格式视频</Radio>
                <Radio value={'avi'}>导出AVI格式视频</Radio>
              </Radio.Group>
            </div>
          ),
          closable: true,
          okText: '确定下载', // 更改确定按钮的文本
          cancelText: '取消下载', // 更改确定按钮的文本
          icon: null, // 禁用默认图标
          width: '40%', // 自定义宽度
          okButtonProps: {
            className: 'custom-ok-button', // 自定义类名
            style: { background: 'blue', borderColor: 'blue' }, // 自定义样式
          },
          onOk() {
            console.log(value);
            let title = '';
            let message = '';
            // 创建一个用于存储 canvas 的数组
            const canvases = [];
            const base64Imgs = [];
            const videoFrames = [];
            // 创建一个新的压缩包
            const zip = new JSZip();
            title = '下载视频';
            message = '转换当前序列的全部图像为视频中，请等待....';
            uiNotificationService.show({
              title: title,
              message: message,
              type: 'info',
              duration: 5000,
            });

            const num = imageIds.length;
            // 遍历 imageIds 并将每个图像添加到 canvases 数组中
            imageIds.forEach((imageId, index) => {
              imageLoader.loadAndCacheImage(imageId).then(image => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = image.width;
                canvas.height = image.height;
                const imageData = ctx.createImageData(
                  image.width,
                  image.height
                );
                const pixelData = image.getPixelData();

                for (let i = 0; i < pixelData.length; i++) {
                  // 由于 pixelData 是单通道的灰度图像，所以 R、G、B 都使用相同的值
                  imageData.data[i * 4] = pixelData[i];
                  imageData.data[i * 4 + 1] = pixelData[i];
                  imageData.data[i * 4 + 2] = pixelData[i];
                  imageData.data[i * 4 + 3] = 255; // 设置透明度为不透明
                }
                ctx.putImageData(imageData, 0, 0);
                const dataUrl = canvas.toDataURL('image/png');

                videoFrames.push(
                  ctx.getImageData(0, 0, canvas.width, canvas.height)
                );
                canvases.push(canvas);
                base64Imgs.push(dataUrl);

                if (base64Imgs.length == imageIds.length) {
                  tsWhammy
                    .fixImageDataList(base64Imgs, {
                      width: 500,
                      height: 500,
                      backgroundColor: '#fff',
                    })
                    .then(arrs => {
                      const num = Math.ceil(imageIds.length / 10);
                      const blob2 = tsWhammy.fromImageArrayWithOptions(arrs, {
                        duration: num,
                      });
                      const videoUrl3 = URL.createObjectURL(blob2);
                      const a3 = document.createElement('a');
                      a3.href = videoUrl3;
                      a3.download =
                        'MedicalImagingVideo' + new Date() + '.' + value;
                      a3.click();
                    });
                }
              });
            });
          },
        });
      };
      openModal();
    }
    if (args.itemId === 'View3D') {
      react();
      if (!runTimeHangingProtocolId) {
        const enabledElement =
          getActiveViewportEnabledElement(viewportGridService);
        // if (enabledElement.viewport.imageIds.length<40){
        //     uiNotificationService.show({
        //         title: '生成3D失败',
        //         message: '无法转换少于40张图片的序列。',
        //         type: 'error',
        //         duration: 3000,
        //     });
        //     return;
        // }

        if (window.location.href.indexOf('dicomlocal?') > -1) {
          navigate({
            pathname: '/viewer/dicomlocal',
            search:
              `?StudyInstanceUIDs=` +
              window.studyinstanceuids +
              '&hangingprotocolId=mprAnd3DVolumeViewport' +
              (window.layoutFlag ? '&layout=topAndButtom' : ''),
          });
          return;
        }

        const ifr = window.parent.document.getElementById('view3D');
        if (ifr) {
          // const parentElement = ifr.parentNode;
          // parentElement.removeChild(ifr);
          ifr.style.zIndex = '98';
          return;
        }

        // 获取目标div元素
        const targetDiv = document.querySelector('.bg-black');
        // window.fusionIamge = targetDiv.innerHTML;
        // targetDiv.innerHTML = '';
        const overlay = document.createElement('div');
        overlay.classList.add('overlay');
        let num = 5;
        overlay.innerHTML =
          "<div class='divlight'><div class='spanTitlelight'>正在渲染3D页面，预计还需<span class='highlight'>" +
          num +
          "</span>秒，请等待...</div><div class='spanTextlight'>在浏览器内进行3D渲染是一项比较费时费力的任务,有损压缩尤其费时,请耐心等待。尤其建议请在性能较好的设备上进行3D渲染!</div></div>"; // 提示文字

        targetDiv.appendChild(overlay);
        // 获取要渲染内容的URL
        const url =
          window.location.href + '&hangingprotocolId=mprAnd3DVolumeViewport';
        iframe = document.createElement('iframe');
        iframe.id = 'view3D';
        iframe.src = url;
        // 设置 iframe 的基本样式
        iframe.style.width = '100%'; // 设置宽度为 100%
        iframe.style.height = '100vh'; // 设置高度为视口高度
        iframe.style.border = 'none'; // 移除边框
        iframe.style.position = 'absolute';
        iframe.style.top = '0';
        iframe.style.zIndex = '-1';
        targetDiv.appendChild(iframe);
        const timer = setInterval(() => {
          num--;
          overlay.innerHTML =
            "<div class='divlight'><div class='spanTitlelight'>正在渲染3D页面，预计还需<span class='highlight'>" +
            num +
            "</span>秒，请等待...</div><div class='spanTextlight'>在浏览器内进行3D渲染是一项比较费时费力的任务,有损压缩尤其费时,请耐心等待。尤其建议请在性能较好的设备上进行3D渲染!</div></div>"; // 提示文字
          if (num == 0) {
            clearInterval(timer);
          }
        }, 1000);
        // 监听 iframe 的加载事件，一旦加载完成，隐藏遮罩层
        iframe.addEventListener('load', () => {
          setTimeout(() => {
            iframe.style.zIndex = '98';
            overlay.style.display = 'none'; // 隐藏遮罩层
          }, 4000);
        });
        return;
      } else {
        if (window.location.href.indexOf('dicomlocal?') > -1) {
          navigate({
            pathname: '/viewer/dicomlocal',
            search:
              `?StudyInstanceUIDs=` +
              window.studyinstanceuids +
              (window.layoutFlag ? '&layout=topAndButtom' : ''),
          });
          return;
        }
        // window.location.href = `viewer?StudyInstanceUIDs=${urlId}`;
        const ifr = window.parent.document.getElementById('view3D');
        if (ifr) {
          // const parentElement = ifr.parentNode;
          // parentElement.removeChild(ifr);
          ifr.style.zIndex = '-1';
        }
        return;
      }
    }
    if (args.itemId == 'MPR') {
      selectChange(0);
      react();

      const enabledElement =
        getActiveViewportEnabledElement(viewportGridService);

      if (!enabledElement.viewport.imageIds) {
        if (window.layoutFlag) {
          toolbarService.createButtonSection('primary', [
            'WindowLevel',
            'Layout',
            'ColorOverlay',
            'Length',
            'Bidirectional',
            'Annotation',
            'Ellipse',
            'Circle',
            'Rectangle',
            'Angle',
            'CobbAngle',
            'Sharpening',
            'Probe',
            'Freehandline',
            'LengthCTR',
            'Reset',
            'cine',
            'MPR',
            'View3D',
            'FusionIamge',
            'Capture',
            'Pan',
            'Crosshairs',
            'Zoom',
            'ShowText',
            'OrderFeature',
          ]);
        } else {
          toolbarService.createButtonSection('primary', [
            'Layout',
            'ColorOverlay',
            'UploadAndDownload',
            'WindowLevel',
            'Length',
            'Bidirectional',
            'Annotation',
            'Ellipse',
            'Circle',
            'Rectangle',
            'Angle',
            'CobbAngle',
            'Sharpening',
            'Probe',
            'Freehandline',
            'LengthCTR',
            'cine',
            'Reset',
            'MPR',
            'View3D',
            'FusionIamge',
            'StackScroll',
            'ReferenceLines',
            'Capture',
            'Pan',
            'FreeRotation',
            'Crosshairs',
            'rotate',
            'horizontally',
            'invert',
            'Zoom',
            'Magnify',
            'ShowText',
          ]);
        }
        window.config.mprFlag = args.commands[0].commandOptions.toggledState;
      } else {
        const viewportInfo = cornerstoneViewportService.getViewportInfo(
          enabledElement.viewportId
        );
        const displaySet = displaySetService.getDisplaySetByUID(
          viewportInfo.viewportData.data.displaySetInstanceUID
        );
        if (displaySet.messages.messages.length != 0) {
          uiNotificationService.show({
            title: '生成MPR失败',
            message: '当前影像非完整序列，存在缺失，无法合成MPR。',
            type: 'error',
            duration: 3000,
          });
          return;
        }
        if (enabledElement.viewport.imageIds.length < 20) {
          uiNotificationService.show({
            title: '生成MPR失败',
            message: '序列图像过少。',
            type: 'error',
            duration: 3000,
          });
          return;
        }

        if (enabledElement.viewport.modality == 'PT') {
          uiNotificationService.show({
            title: '生成MPR失败',
            message: 'PT格式图像暂不支持生成MPR。',
            type: 'error',
            duration: 3000,
          });
          return;
        }

        toolbarService.createButtonSection('primary', [
          'Length',
          'Bidirectional',
          'Annotation',
          'Ellipse',
          'Circle',
          'Rectangle',
          'Angle',
          'CobbAngle',
          'Sharpening',
          'Probe',
          'Freehandline',
          'LengthCTR',
          'cine',
          'Reset',
          'MPR',
          'View3D',
          'ReferenceLines',
          'Pan',
          'Crosshairs',
          'Zoom',
          'ShowText',
        ]);
        window.config.mprFlag = args.commands[0].commandOptions.toggledState;
      }
    }
    toolbarService.recordInteraction(args);
  };

  // Could track buttons and state separately...?
  useEffect(() => {
    const { unsubscribe: unsub1 } = toolbarService.subscribe(
      toolbarService.EVENTS.TOOL_BAR_MODIFIED,
      () => setToolbarButtons(toolbarService.getButtonSection('primary'))
    );
    const { unsubscribe: unsub2 } = toolbarService.subscribe(
      toolbarService.EVENTS.TOOL_BAR_STATE_MODIFIED,
      () => setButtonState({ ...toolbarService.state })
    );
    setToolbarButtons(toolbarService.getButtonSection('primary'));
    return () => {
      unsub1();
      unsub2();
    };
  }, [toolbarService]);
  const [{ cines }, cineService] = useCine();
  const cine = cines[activeViewportIndex];
  const isPlaying = (cine && cine.isPlaying) || false;
  const [valueGroup, setValueGroup] = useState(0);
  const [value3D, setValue3D] = useState('CT-Bone');

  const actionsVr = () => {
    const renderingEngine = cornerstoneViewportService.getRenderingEngine();
    const { viewports } = viewportGridService.getState();
    viewports.forEach(v => {
      const viewport = renderingEngine.getViewport(v.viewportId);
      let Colormap = {
        name: 'hot_iron', //
        // RGBPoints: [
        //   0, 0, 0, 0, 128, 0.8, 0.2, 0.2, 242, 1, 1, 0.1, 255, 1, 1, 0.5,
        // ],
        RGBPoints: [
          0,
          0,
          0,
          0, // 黑色
          248,
          0.98,
          0.65,
          0.47, // 一些米红色
          248,
          0.98,
          0.65,
          0.47, // 一些米红色
          253,
          0.9,
          0.9,
          0.5, // 一些米红色
        ],
        ColorSpace: 'RGB',
      };
      if (viewport) {
        // viewport.setProperties({
        //   colormap: { name: 'erdc_red2yellow_BW' },
        // });
        viewport.setColormap(Colormap);
        //erdc_red2yellow_BW
        let upper = 489;
        let lower = 10;
        viewport.setProperties({
          voiRange: { upper, lower },
        });
        viewport.render();
      }
    });
  };
  if (valueGroup == 4) {
    actionsVr();
  }
  const selectChange = newValue => {
    console.log('radio checked', newValue);
    // @ts-ignore
    setValueGroup(newValue);
    const enabledElement = getActiveViewportEnabledElement(viewportGridService);
    const toolGroup = cornerstoneTools.ToolGroupManager.getToolGroupForViewport(
      enabledElement.viewportId,
      enabledElement.renderingEngineId
    );
    console.log(toolGroup);
    if (toolGroup._toolInstances.Crosshairs) {
      //1最白MIP0 MPR2最黑MINIP3AIP
      toolGroup.setToolConfiguration('Crosshairs', {
        slabThicknessBlendMode: newValue == 4 ? 1 : Number(newValue),
      });
      // toolGroup.setToolActive("Crosshairs");

      let Elements = getEnabledElements();

      Elements.forEach((Element, index) => {
        let viewport = Element.viewport;
        // viewport.voi.crosshairDistance = 10
        // viewport.render();
        // cornerstoneTools.referenceLines.updateCachedStats({ deltaY: 0.5 }, viewport.element);
      });

      const keysIterator = enabledElement.viewport._actors.keys();
      // @ts-ignore
      for (const key of keysIterator) {
        const startIndex = key.indexOf(':'); // 找到冒号的位置
        if (startIndex !== -1) {
          const extractedKey = key.split(':')[1]; // 提取冒号前的部分
          console.log(extractedKey); // 这里就是 'cornerstoneStreamingImageVolume'
          if (newValue !== 4) {
            displaySetService.setDisplaySetMetadataInvalidated(extractedKey);
          }
        }
      }
      if (newValue == 4) {
        actionsVr();
      }
    }
  };

  const handleSelectChange = e => {
    setValue3D(e);
    toolbarService.recordInteraction({
      groupId: e,
      itemId: e,
      interactionType: 'action',
      commands: [
        {
          commandName: 'set3DVolume',
          commandOptions: {
            toolName: e,
          },
          context: 'CORNERSTONE',
        },
      ],
    });
  };

  return (
    <>
      {window.layoutFlag ? (
        <>
          {toolbarButtons.map((toolDef, index) => {
            const { id, Component, componentProps } = toolDef;
            // TODO: ...
            let isActive;
            if (componentProps.type === 'toggle') {
              isActive = buttonState.toggles[id];
            }
            if (id == 'View3D' && runTimeHangingProtocolId) {
              isActive = true;
            }
            const onChange = (isPlaying: boolean) => {
              let Elements = getEnabledElements();
              let num = 0;
              if (
                Elements[activeViewportIndex].viewport.type == 'volume3d' &&
                Elements.length > 4
              ) {
                Elements = Elements.slice(-4);
                num = Elements.length - 4;
              }
              if (
                Elements[Number(activeViewportIndex)].viewport.type ==
                'volume3d'
              ) {
                uiNotificationService.show({
                  title: '3D',
                  message: '3D绘图不支持滚动播放。',
                  type: 'info',
                  duration: 3000,
                });
              } else {
                cineService.setCine({
                  id: Number(window.config.viewportIndex + num),
                  isPlaying,
                });
              }
            };
            return (
              <div>
                <div
                  key={id}
                  style={{
                    minWidth: '40px',
                    textAlign: 'center',
                    fontWeight: '600',
                    border:
                      id == 'WindowLevel' ||
                      id == 'Layout' ||
                      id == 'ColorOverlay' ||
                      id == 'UploadAndDownload' ||
                      id == 'OrderFeature'
                        ? '1px solid rgb(37, 37, 37)'
                        : null,
                    borderRadius:
                      id == 'WindowLevel' ||
                      id == 'Layout' ||
                      id == 'ColorOverlay' ||
                      id == 'UploadAndDownload' ||
                      id == 'OrderFeature'
                        ? '10px'
                        : null,
                  }}
                >
                  {id == 'cine' ? (
                    <>
                      <Player
                        // className="absolute left-1/2 -translate-x-1/2 bottom-3"
                        isPlaying={isPlaying}
                        // onClose={handleCineClose}
                        onPlayPauseChange={isPlaying => onChange(isPlaying)}
                        servicesManager={servicesManager}
                        viewportIndex={activeViewportIndex}
                        onFrameRateChange={frameRate => onChange(isPlaying)}
                      />
                    </>
                  ) : (
                    <Component
                      id={id}
                      {...componentProps}
                      bState={buttonState}
                      isActive={isActive}
                      onInteraction={onInteraction}
                      servicesManager={servicesManager}
                    />
                  )}
                </div>
              </div>
            );
          })}

          {!window.config.mprFlag ? (
            // ||
            // window.location.href.indexOf(
            //   'hangingprotocolId=mprAnd3DVolumeViewport'
            // ) > -1
            <div
              style={{
                position: 'absolute',
                top: '88px',
                left: '-2px',
                zIndex: 9,
              }}
            >
              <Select
                value={valueGroup}
                style={{ width: 120 }}
                popupClassName="custom-dropdown"
                bordered={false}
                onChange={newValue => {
                  selectChange(newValue);
                }}
                options={[
                  { value: 0, label: 'MPR' },
                  { value: 3, label: 'AIP' },
                  { value: 1, label: 'MIP' },
                  { value: 2, label: 'MinIP' },
                  { value: 4, label: 'VR' },
                ]}
              />
            </div>
          ) : undefined}
        </>
      ) : (
        <>
          {toolbarButtons.map((toolDef, index) => {
            const { id, Component, componentProps } = toolDef;
            // TODO: ...

            // isActive if:
            // - id is primary?
            // - id is in list of "toggled on"?
            let isActive;
            if (componentProps.type === 'toggle') {
              isActive = buttonState.toggles[id];
            }
            if (id == 'View3D' && runTimeHangingProtocolId) {
              isActive = true;
            }
            const onChange = (isPlaying: boolean) => {
              let Elements = getEnabledElements();
              let num = 0;
              if (
                Elements[activeViewportIndex].viewport.type == 'volume3d' &&
                Elements.length > 4
              ) {
                Elements = Elements.slice(-4);
                num = Elements.length - 4;
              }
              if (
                Elements[Number(activeViewportIndex)].viewport.type ==
                'volume3d'
              ) {
                uiNotificationService.show({
                  title: '3D',
                  message: '3D绘图不支持滚动播放。',
                  type: 'info',
                  duration: 3000,
                });
              } else {
                cineService.setCine({
                  id: Number(window.config.viewportIndex + num),
                  isPlaying,
                });
              }
            };
            // Also need... to filter list for splitButton, and set primary based on most recently clicked
            // Also need to kill the radioGroup button's magic logic
            // Everything should be reactive off these props, so commands can inform ToolbarService

            // These can... Trigger toolbar events based on updates?
            // Then sync using useEffect, or simply modify the state here?
            return (
              // The margin for separating the tools on the toolbar should go here and NOT in each individual component (button) item.
              // This allows for the individual items to be included in other UI components where perhaps alternative margins are desired.
              <div>
                <div
                  key={id}
                  style={{
                    margin:
                      id == 'FitWindow' || id == 'FitImage'
                        ? '0.4rem 0rem 0.2rem 0rem'
                        : '0.2rem',
                    marginLeft: id == 'FitImage' ? '0.2rem' : null,
                    minWidth: '62px',
                    textAlign: 'center',
                    // fontWeight: '400',
                    // borderBottom: id == 'WindowLevel' ||  id == 'Layout' ||  id == 'ColorOverlay' ||  id == 'UploadAndDownload' ? '1px solid' : null,
                    border:
                      id == 'FitWindow' || id == 'FitImage'
                        ? '1px solid rgb(113 111 111)'
                        : id == 'WindowLevel' ||
                          id == 'Layout' ||
                          id == 'ColorOverlay' ||
                          id == 'UploadAndDownload'
                        ? '1px solid rgb(37, 37, 37)'
                        : null,
                    borderRadius:
                      id == 'WindowLevel' ||
                      id == 'Layout' ||
                      id == 'ColorOverlay' ||
                      id == 'UploadAndDownload'
                        ? '10px'
                        : null,
                  }}
                >
                  {id == 'cine' ? (
                    <Player
                      // className="absolute left-1/2 -translate-x-1/2 bottom-3"
                      isPlaying={isPlaying}
                      // onClose={handleCineClose}
                      onPlayPauseChange={isPlaying => onChange(isPlaying)}
                      servicesManager={servicesManager}
                      viewportIndex={activeViewportIndex}
                      onFrameRateChange={frameRate => onChange(isPlaying)}
                    />
                  ) : (
                    <Component
                      id={id}
                      {...componentProps}
                      bState={buttonState}
                      isActive={isActive}
                      onInteraction={onInteraction}
                      servicesManager={servicesManager}
                    />
                  )}

                  {id == 'cine' ||
                  id == 'ColorOverlay' ||
                  id == 'UploadAndDownload' ||
                  id == 'WindowLevel' ||
                  id == 'Layout' ||
                  id == 'FitWindow' ||
                  id == 'FitImage' ||
                  id == 'fusionPTColormap' ? undefined : (
                    <div style={{ textAlign: 'center', fontSize: '12px' }}>
                      {translation[id] != undefined ? translation[id].substring(0, 5) : id}
                    </div>
                  )}
                </div>
                {id == 'WindowLevel' ||
                id == 'ShowText' ||
                id == 'fusionPTColormap' ? (
                  <div
                    style={{
                      borderTop: '1px solid',
                      height: '1px',
                      width: ' 280px',
                      position: 'absolute',
                      left: ' 0px',
                    }}
                  ></div>
                ) : undefined}
              </div>
            );
          })}
          {!window.config.mprFlag ? (
            // ||
            // window.location.href.indexOf(
            //   'hangingprotocolId=mprAnd3DVolumeViewport'
            // ) > -1
            <div
              style={{
                position: 'fixed',
                top: '71px',
                left: '146px',
                zIndex: 9,
                color: 'rgb(225 225 225)',
              }}
            >
              <Select
                value={valueGroup}
                style={{ width: 120 }}
                popupClassName="custom-dropdown"
                bordered={false}
                onChange={newValue => {
                  selectChange(newValue);
                }}
                options={[
                  { value: 0, label: 'MPR' },
                  { value: 3, label: 'AIP' },
                  { value: 1, label: 'MIP' },
                  { value: 2, label: 'MinIP' },
                  { value: 4, label: 'VR' },
                ]}
              />
            </div>
          ) : undefined}

          {window.location.href.indexOf(
            'hangingprotocolId=mprAnd3DVolumeViewport'
          ) > -1 ? (
            <div
              style={{
                position: 'fixed',
                top: '30px',
                right: '900px',
                zIndex: 9,
              }}
            >
              <Select
                value={value3D}
                size="small"
                popupClassName="custom-dropdown"
                bordered={false}
                style={{ width: 120, fontWeight: 400 }}
                onChange={handleSelectChange}
                options={[
                  { value: 'CT-AAA', label: 'CT-AAA' },
                  { value: 'CT-AAA2', label: 'CT-AAA2' },
                  { value: 'CT-Bone', label: 'CT-Bone' },
                  { value: 'CT-Bones', label: 'CT-Bones' },
                  { value: 'CT-Cardiac', label: 'CT-Cardiac' },
                  { value: 'CT-Cardiac2', label: 'CT-Cardiac2' },
                  { value: 'CT-Cardiac3', label: 'CT-Cardiac3' },
                  {
                    value: 'CT-Chest-Contrast-Enhanced',
                    label: 'CT-Chest-Contrast-Enhanced',
                  },
                  {
                    value: 'CT-Chest-Vessels',
                    label: 'CT-Chest-Vessels',
                  },
                  {
                    value: 'CT-Coronary-Arteries',
                    label: 'CT-Coronary-Arteries',
                  },
                  {
                    value: 'CT-Coronary-Arteries-2',
                    label: 'CT-Coronary-Arteries-2',
                  },
                  {
                    value: 'CT-Coronary-Arteries-3',
                    label: 'CT-Coronary-Arteries-3',
                  },
                  {
                    value: 'CT-Cropped-Volume-Bone',
                    label: 'CT-Cropped-Volume-Bone',
                  },
                  { value: 'CT-Fat', label: 'CT-Fat' },
                  {
                    value: 'CT-Liver-Vasculature',
                    label: 'CT-Liver-Vasculature',
                  },
                  { value: 'CT-Lung', label: 'CT-Lung' },
                  { value: 'CT-MIP', label: 'CT-MIP' },
                  { value: 'CT-Muscle', label: 'CT-Muscle' },
                  {
                    value: 'CT-Pulmonary-Arteries',
                    label: 'CT-Pulmonary-Arteries',
                  },
                  { value: 'CT-Soft-Tissue', label: 'CT-Soft-Tissue' },
                  { value: 'CT-Air', label: 'CT-Air' },
                  { value: 'MR-Angio', label: 'MR-Angio' },
                  { value: 'MR-Default', label: 'MR-Default' },
                  { value: 'MR-MIP', label: 'MR-MIP' },
                  { value: 'MR-T2-Brain', label: 'MR-T2-Brain' },
                  { value: 'DTI-FA-Brain', label: 'DTI-FA-Brain' },
                ]}
              />
            </div>
          ) : undefined}
        </>
      )}
    </>
  );
}
