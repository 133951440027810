import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.css';

const ColorOverlayMenuItem = ({title, subtitle, isSelected, index}) => (
    <>
        <div
            className={classNames(
                'flex flex-row items-center p-3 h-8 w-full bg-primary-dark',
                isSelected && 'bg-primary-dark'
            )}
        >
            <span className=" flex-1  font-light text-sm whitespace-nowrap"
                  style={{textAlign: 'left'}}>{index + 1}.</span>
            <span className=" mr-2 text-base whitespace-nowrap"
                  style={{width: '46px', fontSize: '12px', textAlign: 'left'}}>{subtitle}</span>
            <span className="ml-5 text-sm whitespace-nowrap" style={{
                backgroundImage: subtitle == 'Hot' ? 'linear-gradient(to right, red, yellow, green, blue)' :
                    subtitle == 'CoolWarm' ? 'linear-gradient(to right, #3b4cc0, #82c3e6, #b8e3ff, #ffffcc, #ffcc66, #ff603b)' :
                        subtitle == 'Grayscale' ? 'linear-gradient(to right, #000000, #ffffff)' :
                            subtitle == 'Topography' ? 'linear-gradient(to right, #1f7a1f, #6b8f6b, #b5a8b5, #f0c1f0, #ffe8ff)' :
                                subtitle == 'Volcano' ? 'linear-gradient(to right, #f00, #ff6f00, #ffcc00, #fff)' :
                                    subtitle == 'Hot Iron' ? 'linear-gradient(to right, #FF0000, #FFFF00)' :
                                        subtitle == 'S PET' ? 'linear-gradient(to right, #9400D3, #4B0082, #0000FF, #00FF00, #FFFF00, #FF7F00, #FF0000)' :
                                            subtitle == 'Red Hot' ? 'linear-gradient(to right, #000000, #FF0000)' :
                                                subtitle == 'Perfusion' ? 'linear-gradient(to right, #0000FF, #00FF00, #FF0000)' :
                                                    subtitle == 'Rainbow' ? 'linear-gradient(to right, #FF0000, #FF7F00, #FFFF00, #00FF00, #0000FF, #4B0082, #8B00FF)' :
                                                        subtitle == 'SUV' ? 'linear-gradient(to right, #000000, #3b4cc0, #82c3e6, #b8e3ff, #ffffcc, #ffcc66, #ff603b)' :
                                                            subtitle == 'GE 256' ? 'linear-gradient(to right, #000000, #13007c, #2500ff, #008000, #00ff00, #ffbf00, #ff0000, #ffffff)' :
                                                                subtitle == 'GE' ? 'linear-gradient(to right, #9400d3, #4b0082, #0000ff, #00ff00, #ffff00, #ff7f00, #ff0000)' :
                                                                    subtitle == 'Siemens' ? 'linear-gradient(to right, #000000, #ff0000, #ff9000, #fff600, #ffffff)' :
                                                            'linear-gradient(to right, black, black)',
                border: '1px solid #fff',
                width: '50px',
            }}>
                <span style={{width: '100%'}}>{'-'}</span>

      </span>
        </div>
    </>
);

ColorOverlayMenuItem.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
};

export default ColorOverlayMenuItem;
