import React from 'react';
// import OHIFViewer from '@ohif/viewer';
// import { DICOMSR, initCornerstoneTools, ColorOverlayTool } from '@ohif/extension-cornerstone';
// import cornerstone from 'cornerstone-core';

// 初始化 cornerstoneTools 和 DICOM SR 模块
// initCornerstoneTools([ColorOverlayTool, DICOMSR]);

const studies = [
  {
    StudyInstanceUID: '1.2.3.4.5',
    series: [
      {
        SeriesInstanceUID: '1.2.3.4.5.1',
        displaySets: [
          {
            displaySetInstanceUID: '1.2.3.4.5.1.1',
            SOPInstanceUIDs: ['1.2.3.4.5.1.1.1'],
          },
        ],
      },
    ],
  },
];

/**
 * Calibration Line tool works almost the same as the
 */
class ColorOverlayTextTool  {
  static toolName = 'ColorOverlay';


}

export default ColorOverlayTextTool;

