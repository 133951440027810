import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PanelService, ServicesManager, Types } from '@ohif/core';

import { Button, Icon, IconButton, Tooltip } from '../';
import Toolbar from '../../../../../extensions/default/src/Toolbar/Toolbar';
import ErrorBoundary from '../ErrorBoundary';
import 'swiper/css';
import 'swiper/css/navigation';
import './style.css';

const bg3232323 = {
  backgroundColor: '#323232'
  // marginBottom: '2px',
};
const bg323232 = {
  backgroundColor: '#323232',
  borderBottom:  '1px solid'
  // marginBottom: '2px',
};

const baseClasses =
  'transition-all duration-300 ease-in-out h-100 text-common-light border-black justify-start box-content flex flex-col';

const classesMap = {
  open: {
    // left: `mr-1`,
    // right: `ml-1`,
  },
  closed: {
    left: ` items-end`,
    right: ` items-start`,
  },
};

const openStateIconName = {
  left: 'push-left',
  right: 'push-right',
};

const position = {
  left: {
    right: 5,
  },
  right: {
    left: 5,
  },
};

const SidePanel = ({
  servicesManager,
  side,
  className,
  activeTabIndex: activeTabIndexProp,
  tabs,
  children,
}) => {
  const panelService: PanelService = servicesManager.services.panelService;
  const borderSize = 4;
  const expandedWidth = tabs.length === 2 ? 280 : 148;
  const collapsedWidth = 25;
  let baseStyle
  if(window.layoutFlag){

    baseStyle = {
      maxWidth: `100%`,
      width: `100%`,
    };
  }else{

    baseStyle = {
      maxWidth: `${expandedWidth}px`,
      width: `${expandedWidth}px`,
      backgroundColor:'#323232'
    };
  }

  const leftStyle = {
    width: '100vw',
    height: '150px',
    position: 'absolute',
    overflow: 'auto',
    whiteSpace: 'nowrap',
  };
  const mobileStyle = {
    position: 'fixed',
    bottom: 0,
    marginRight: 0,
    height: '5rem',
    backgroundColor: 'rgb(50, 50, 50)',
  };
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
  // if (isMobile) {
  //   console.log('当前设备为移动端');
  // } else {
  //   console.log('当前设备为PC端');
  // }
  const collapsedHideWidth = expandedWidth - collapsedWidth - borderSize;
  const styleMap = {
    open: {
      left: { marginLeft: '0px' },
      right: { marginRight: '0px' },
    },
    closed: {
      left: { marginLeft: `-${collapsedHideWidth}px` },
      right: { marginRight: `-${collapsedHideWidth}px` },
    },
  };

  const { t } = useTranslation('SidePanel');

  // Tracks whether this SidePanel has been opened at least once since this SidePanel was inserted into the DOM.
  // Thus going to the Study List page and back to the viewer resets this flag for a SidePanel.
  const [hasBeenOpened, setHasBeenOpened] = useState(
    activeTabIndexProp !== null
  );
  const [panelOpen, setPanelOpen] = useState(true);
  const [activeTabIndex, setActiveTabIndex] = useState(activeTabIndexProp ?? 0);
  const swiperRef = useRef() as any;
  const [swiper, setSwiper] = useState<any>();

  const prevRef = React.useRef();
  const nextRef = React.useRef();
  let mobileTab;
  if (isMobile && side === 'left') {
    mobileTab = leftStyle;
  } else if (isMobile && side === 'right') {
    mobileTab = mobileStyle;
  } else {
    mobileTab = baseStyle;
  }

  const openStatus = panelOpen ? 'open' : 'closed';
  let style = Object.assign({}, styleMap[openStatus][side], mobileTab);

  const ActiveComponent = tabs[activeTabIndex].content;

  useEffect(() => {
    if (panelOpen && swiper) {
      swiper.slideTo(activeTabIndex, 500);
    }
  }, [panelOpen, swiper]);

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  const updatePanelOpen = useCallback((panelOpen: boolean) => {
    setPanelOpen(panelOpen);
    if (panelOpen) {
      setHasBeenOpened(true);
    }
  }, []);

  const updateActiveTabIndex = useCallback(
    (activeTabIndex: number) => {
      setActiveTabIndex(activeTabIndex);
      updatePanelOpen(true);
    },
    [updatePanelOpen]
  );

  useEffect(() => {
    const activatePanelSubscription = panelService.subscribe(
      panelService.EVENTS.ACTIVATE_PANEL,
      (activatePanelEvent: Types.ActivatePanelEvent) => {
        if (!hasBeenOpened || activatePanelEvent.forceActive) {
          const tabIndex = tabs.findIndex(
            tab => tab.id === activatePanelEvent.panelId
          );
          if (tabIndex !== -1) {
            updateActiveTabIndex(tabIndex);
          }
        }
      }
    );

    return () => {
      activatePanelSubscription.unsubscribe();
    };
  }, [tabs, hasBeenOpened, panelService, updateActiveTabIndex]);

  const getCloseStateComponent = () => {
    const _childComponents = Array.isArray(tabs) ? tabs : [tabs];
    return (
      <>
        <div
          className={classnames(
            ' h-[28px] flex items-center w-full cursor-pointer',
            side === 'left' ? 'pr-2 justify-end' : 'pl-2 justify-start'
          )}
          onClick={() => {
            updatePanelOpen(prev => !prev);
          }}
          data-cy={`side-panel-header-${side}`}
        >
          <Icon
            name={'push-left'}
            className={classnames(
              'text-common-light',
              side === 'left' && 'transform rotate-180'
            )}
          />
        </div>
      </>
    );
  };

  return (
    <div
      className={classnames(
        className,
        baseClasses,
        classesMap[openStatus][side]
      )}
      style={{ ...style }}
    >
      {panelOpen ? (
        <React.Fragment>
          {/** Panel Header with Arrow and Close Actions */}
          {!isMobile && !window.layoutFlag ? (
            side === 'left' ? (
              <div
                onClick={() => {
                  updatePanelOpen(prev => !prev);
                  // slideToActivePanel();
                }}
                style={{
                  position: 'absolute',
                  top: '0.5rem',
                  left: '9.3rem',
                  cursor: 'pointer',
                }}
              >
                <Icon
                  name={openStateIconName[side]}
                  className={classnames(
                    'text-common-light absolute',
                    side === 'left' && 'order-last'
                  )}
                  style={{ ...position[side] }}
                />
              </div>
            ) : (
              <div
                style={bg323232}
                data-cy={`side-panel-header-${side}`}
              >
                <Button
                  variant="text"
                  color="inherit"
                  border="none"
                  rounded="none"
                  className="flex flex-row flex-static items-center px-3 relative w-full"
                  name={tabs.length === 1 ? `${tabs[activeTabIndex].name}` : ''}
                >
                  <Icon
                    name={openStateIconName[side]}
                    className={classnames(
                      'text-common-light absolute',
                      side === 'left' && 'order-last'
                    )}
                    onClick={() => {
                      updatePanelOpen(prev => !prev);
                      // slideToActivePanel();
                    }}
                    style={{ ...position[side] }}
                  />
                  <span>常用工具</span>
                   {/*Todo: ass secondary label here*/}
                  <span className="text-common-light">
                    {tabs.length === 1 &&
                      (t(tabs[activeTabIndex].label) as string)}
                  </span>
                </Button>
              </div>
            )
          ) : null}
          {tabs.length > 1 &&
            _getMoreThanOneTabLayout(
              swiperRef,
              setSwiper,
              prevRef,
              nextRef,
              tabs,
              activeTabIndex,
              updateActiveTabIndex,
              servicesManager,
              children
            )}
          <ActiveComponent />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {getCloseStateComponent()}
        </React.Fragment>
      )}
    </div>
  );
};

SidePanel.defaultProps = {
  defaultComponentOpen: null,
};

SidePanel.propTypes = {
  servicesManager: PropTypes.instanceOf(ServicesManager),
  side: PropTypes.oneOf(['left', 'right']).isRequired,
  className: PropTypes.string,
  activeTabIndex: PropTypes.number,
  tabs: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        iconName: PropTypes.string.isRequired,
        iconLabel: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        content: PropTypes.func, // TODO: Should be node, but it keeps complaining?
      })
    ),
  ]),
};

function _getMoreThanOneTabLayout(
  swiperRef: any,
  setSwiper: React.Dispatch<any>,
  prevRef: React.MutableRefObject<undefined>,
  nextRef: React.MutableRefObject<undefined>,
  tabs: any,
  activeTabIndex: any,
  updateActiveTabIndex,
  servicesManager,
  children
) {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
  return (
    <div className="flex-static collapse-sidebar relative" style={bg3232323}>
      <div className="w-full">
        <ErrorBoundary context="Primary Toolbar">
          <div className={`${!isMobile && !window.layoutFlag ? 'flexSide' : 'mobileFlexSide'}`}>
            <Toolbar servicesManager={servicesManager} />
          </div>
        </ErrorBoundary>
        {/* <div className="flex items-center">{children}</div> */}

        {/* <Swiper
          onInit={(core: SwiperCore) => {
            swiperRef.current = core.el;
          }}
          simulateTouch={false}
          modules={[Navigation, Pagination, Scrollbar, A11y, Controller]}
          slidesPerView={3}
          spaceBetween={5}
          onSwiper={swiper => setSwiper(swiper)}
          navigation={{
            prevEl: prevRef?.current,
            nextEl: nextRef?.current,
          }}
        >
          {tabs.map((obj, index) => (
            <SwiperSlide key={index}>
              <div
                className={classnames(
                  index === activeTabIndex
                    ? 'bg-secondary-main text-white'
                    : 'text-aqua-pale',
                  'flex cursor-pointer px-4 py-1 rounded-[4px]  flex-col justify-center items-center text-center hover:text-white'
                )}
                key={index}
                onClick={() => {
                  updateActiveTabIndex(index);
                }}
                data-cy={`${obj.name}-btn`}
              >
                <span>
                  <Icon
                    name={obj.iconName}
                    className={classnames(
                      index === activeTabIndex
                        ? 'text-white'
                        : 'text-primary-active'
                    )}
                    style={{
                      width: '22px',
                      height: '22px',
                    }}
                  />
                </span>
                <span className="text-[10px] select-none font-medium whitespace-nowrap mt-[5px]">
                  {obj.label}
                </span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper> */}
      </div>
    </div>
  );
}

export default SidePanel;
