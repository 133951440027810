import React, { ChangeEvent, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { NavBar, Svg, Icon, IconButton, Dropdown } from '../';
import { Popconfirm, Button } from 'antd';
import { ToolOutlined } from '@ant-design/icons';
// @ts-ignore
import { useNavigate } from 'react-router-dom';
const itemStyle = {
  // color: '#e2e8f0',
  marginLeft: '0.3rem',
  fontSize: '12px',
  width: '100%',
};
function Header({
  children,
  menuOptions,
  isReturnEnabled,
  onClickReturnButton,
  isSticky,
  WhiteLabeling,
  servicesManager,
  show,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation('Header');
  let information = {};
  if (servicesManager) {
    const { toolbarService } = servicesManager.services;
    information = toolbarService.getInformation();
  }

  // TODO: this should be passed in as a prop instead and the react-router-dom
  // dependency should be dropped
  const onClickReturn = () => {
    if (isReturnEnabled && onClickReturnButton) {
      onClickReturnButton();
    }
  };

  const CustomLogo = React => {
    return WhiteLabeling.createLogoComponentFn(React);
  };
  let elements = document.getElementsByClassName('flex justify-between flex-1');

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    const url = window.location.href;
    const substringToRemove = '&layout=topAndButtom';
    switch (selectedValue) {
      case 'leftAndRight':
        if (url.indexOf(substringToRemove) > -1) {
          const modifiedUrl = url.replace(substringToRemove, '');
          window.location.href = modifiedUrl;
        }
        break;
      case 'topAndButtom':
        if (url.indexOf(substringToRemove) < 0) {
          window.location.href = window.location.href + substringToRemove;
        }
        break;
      default:
        break;
    }
  };
  const confirm = () => {
    const url = window.location.href.replace(window.location.origin, '');
    const substringToRemove = '&layout=topAndButtom';
    setTimeout(() => {
      if (window.layoutFlag) {
        const modifiedUrl = url.replace(substringToRemove, '');
        let arr = modifiedUrl.split('?');
        navigate({
          pathname: arr[0],
          search: `?` + arr[1],
        });
        // window.location.href = modifiedUrl
      } else {
        let arr = url.split('?');
        navigate({
          pathname: arr[0],
          search: `?` + arr[1] + substringToRemove,
        });
        // window.location.href =  url + substringToRemove
      }
    }, 300);
  };

  const urlParams = new URLSearchParams(window.location.search);
  const StudyInstanceUIDs = urlParams.get('StudyInstanceUIDs')
  const StudyInstanceUIDFlag = window.config.StudyInstanceUIDArr.indexOf(StudyInstanceUIDs) !== -1
  useEffect(() => {
    let dome: any = (
      <div style={itemStyle} className="text-common-light">
        <span style={{ marginRight: '0.5rem' }}>
          姓名：
           {StudyInstanceUIDFlag ? '***' : information?.patientName}
        </span>
        <span style={{ marginRight: '0.5rem' }}>
          检查编号：
           {StudyInstanceUIDFlag ? '***' : information?.MRN}
        </span>
        <span style={{ marginRight: '0.5rem' }}>
          检查日期：
           {StudyInstanceUIDFlag ? '***' : information?.formattedDate}
        </span>
        <span
          style={{
            float: 'right',
            backgroundColor: 'rgb(50, 50, 50)',
            width: '20px',
            marginRight: '12px',
            display:
              window.location.href.indexOf('tmtv?') > -1 ||
              window.location.href.indexOf(
                '&hangingprotocolId=mprAnd3DVolumeViewport'
              ) > -1
                ? 'none'
                : null,
          }}
        >
          <Popconfirm
            placement="bottomRight"
            title={'布局切换'}
            description={
              '您确定要切换到' +
              (window.layoutFlag ? '三列布局' : '三行布局') +
              '吗？'
            }
            onConfirm={confirm}
            okText="Yes"
            cancelText="No"
          >
            <ToolOutlined style={{ fontSize: '18px' }} />
          </Popconfirm>
          {/*<div style={{position:'fixed'}}> <SettingTwoTone /></div>*/}
          {/* <select id="layout" style={{backgroundColor:'rgb(50, 50, 50)',textAlign:'right',width:'25px',border:'0px'}} defaultValue={"q"} onChange={handleChange}>*/}
          {/*   <option value="q" hidden></option>*/}
          {/*   <option value="leftAndRight">【Ⅲ】三列布局</option>*/}
          {/*   <option value="topAndButtom">【☰】三行布局</option>*/}
          {/* </select>*/}
        </span>
      </div>
    );

    for (var i = 0; i < elements.length; i++) {
      ReactDOM.render(dome, elements[i]);
    }

    return () => {
      // 在组件卸载时执行清理操作
      for (var i = 0; i < elements.length; i++) {
        // ReactDOM.unmountComponentAtNode(elements[i]);
      }
    };
  }, []);

  return show ? null : (
    <NavBar
      className="justify-between border-b-1 border-black"
      isSticky={isSticky}
    >
      <div className="flex justify-between flex-1">
        <div className="flex items-center">
          <div style={itemStyle}>
            <span style={{ marginRight: '0.5rem' }}>
              姓名：{information ? information.patientName : ' '}
            </span>
            <span style={{ marginRight: '0.5rem' }}>
              编号：{information ? information.MRN : ' '}
            </span>
            <span style={{ marginRight: '0.5rem' }}>
              日期：{information ? information.formattedDate : ' '}
            </span>
          </div>
          {/* // TODO: Should preserve filter/sort
              // Either injected service? Or context (like react router's `useLocation`?) */}
          {/* <div
            className={classNames(
              'inline-flex items-center mr-3',
              isReturnEnabled && 'cursor-pointer'
            )}
            onClick={onClickReturn}
          >
            {isReturnEnabled && <Icon name="chevron-left" className="w-8 text-primary-active" />}
            <div className="ml-4">{WhiteLabeling ? CustomLogo(React) : <Svg name="logo-ohif" />}</div>
          </div> */}
        </div>
        {/* <div className="flex items-center">{children}</div> */}
        {/* <div className="flex items-center">
          <span className="mr-3 text-lg text-common-light">
            {t('INVESTIGATIONAL USE ONLY')}
          </span>
          <Dropdown id="options" showDropdownIcon={false} list={menuOptions}>
            <IconButton
              id={'options-settings-icon'}
              variant="text"
              color="inherit"
              size="initial"
              className="text-common-light"
            >
              <Icon name="settings" />
            </IconButton>
            <IconButton
              id={'options-chevron-down-icon'}
              variant="text"
              color="inherit"
              size="initial"
              className="text-primary-active"
            >
              <Icon name="chevron-down" />
            </IconButton>
          </Dropdown>
        </div> */}
      </div>
    </NavBar>
  );
}

Header.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isReturnEnabled: PropTypes.bool,
  isSticky: PropTypes.bool,
  onClickReturnButton: PropTypes.func,
  WhiteLabeling: PropTypes.element,
  servicesManager: PropTypes.object.isRequired,
};

Header.defaultProps = {
  isReturnEnabled: true,
  isSticky: false,
};

export default Header;
