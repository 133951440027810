import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {notification, Switch} from 'antd';
import {servicesManager} from "@ohif/viewer/src/App";
import {getEnabledElements} from "@cornerstonejs/core";

import {getIcon, ICONS, addIcon} from '../../components/Icon/getIcon';
import {utilities as csToolsUtils} from "@cornerstonejs/tools";


function LayoutSelector({onSelection, rows, columns}) {
    const [hoveredIndex, setHoveredIndex] = useState();
    const [selected, setSelected] = useState(-1);
    const hoverX = hoveredIndex % columns;
    const hoverY = Math.floor(hoveredIndex / columns);
    const isHovered = index => {
        const x = index % columns;
        const y = Math.floor(index / columns);

        return x <= hoverX && y <= hoverY;
    };
    const {viewportGridService, toolbarService ,displaySetService,uiNotificationService} = servicesManager.services;
    let gridSize = '40px '
    let minGridSize = '10px '
    const myStyle = {
        color: '#fff', fontSize: '15px', width: '90px', float: 'right', paddingTop: '5px'
    };
    let gridArray = []
    if (window.location.href.indexOf("hangingprotocolId=mprAnd3DVolumeViewport") > -1) {
        gridArray = [{x: 1, y: 1}, {x: 1, y: 2}, {x: 2, y: 1}, {x: 2, y: 2}]
    } else {
        gridArray = [{x: 1, y: 1}, {x: 1, y: 2}, {x: 2, y: 1}, {x: 2, y: 2}, {x: 1, y: 3}, {x: 3, y: 1}, {
            x: 2,
            y: 3
        }, {x: 3, y: 2}, {x: 3, y: 3}]
    }


    function LayoutTb(checked) {
        const state = viewportGridService.getState();
        let props = []
        toolbarService.setIndexSyn(true);
        toolbarService.setPositionSyn(false);
        // if (state.viewports.length == 1){
        //     return
        // }
        const activeViewportIndex = state.activeViewportIndex;
        const targetDisplaySet = displaySetService.getDisplaySetByUID(state.viewports[activeViewportIndex].displaySetInstanceUIDs[0])
        if (targetDisplaySet.images.length == 1){
            uiNotificationService.show({
                title: '布局切换失败',
                message:
                    '该功能为多图像平铺布局，请选择（切换）到拥有多张图像的序列再进行使用。',
                type: 'error',
                duration: 6000,
            });
            return
        }
        window.config.layoutFlag = checked
        let key  = ''
        state.viewports.forEach((viewport,index) => {
            // viewportGridService.setActiveViewportIndex(index)
            if (viewport.displaySetInstanceUIDs[0] != targetDisplaySet.displaySetInstanceUID){
                key = viewport.displaySetInstanceUIDs[0]
            }
            let prop =   {
                viewportIndex: index,
                displaySetInstanceUIDs: [targetDisplaySet.displaySetInstanceUID],
            }
            props.push(prop)
        })
        viewportGridService.setLayoutDisplaySetsForViewports(props,key);
    }

    const onChange = (checked: boolean) => {
        if (checked) {
            LayoutTb(checked)
        } else {
            window.config.layoutFlag = checked
            // viewportGridService.reset()
            onSelection({
                numRows: 1,
                numCols: 1,
            });
            toolbarService.setIndexSyn(false);
            toolbarService.setPositionSyn(false);
            // displaySets.forEach((ImageSet,index) => {
            //     let prop =   {
            //         viewportIndex: index,
            //         displaySetInstanceUIDs: [ImageSet.displaySetInstanceUID],
            //     }
            //     props.push(prop)
            // })
            // viewportGridService.setDisplaySetsForViewports(props);
        }
    };
    return (
        <div>
            <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                // gap: '10px',
            }}>
                {gridArray.map((item, gridIndex) => (
                    <span
                        key={gridIndex}
                        style={{
                            display: 'grid',
                            gridTemplateColumns: minGridSize.repeat(columns),
                            gridTemplateRows: minGridSize.repeat(rows),
                            backgroundColor: selected == gridIndex ? '#090c29' : '#333',
                            borderBottom: '1px solid  rgb(151 150 150)'
                        }}
                        onClick={() => {
                            onSelection({
                                numRows: item.y,
                                numCols: item.x,
                            });
                            if (window.config.layoutFlag){
                                setTimeout(() => {
                                    LayoutTb(window.config.layoutFlag)
                                }, 600);
                            }
                        }}
                        onMouseEnter={() => setSelected(gridIndex)}
                        onMouseLeave={() => setSelected(-1)}
                        className="p-2"
                    >
                        <span>
                          {getIcon('no' + (gridIndex + 1))}
                        </span>
                        {/*<span style={myStyle}>*/}
                        {/*    {item.x}*{item.y}*/}
                        {/*</span>*/}
                    </span>
                ))}
            </div>
            {/*<div*/}
            {/*    style={{*/}
            {/*        display: 'grid',*/}
            {/*        gridTemplateColumns: gridSize.repeat(columns),*/}
            {/*        gridTemplateRows: gridSize.repeat(rows),*/}
            {/*        backgroundColor: '#090c29', // primary-dark*/}
            {/*    }}*/}
            {/*    className="p-2"*/}
            {/*>*/}

            {/*{Array.apply(null, Array(rows*columns)).map(function (_, i) {return i;}).map(index => (*/}
            {/*    <div*/}
            {/*        key={index}*/}
            {/*        style={{*/}
            {/*            border: '1px solid white',*/}
            {/*            backgroundColor: isHovered(index) ? '#5acce6' : '#0b1a42',*/}
            {/*        }}*/}
            {/*        className="cursor-pointer"*/}
            {/*        onClick={() => {*/}
            {/*            const x = index % columns;*/}
            {/*            const y = Math.floor(index / columns);*/}
            {/*            if (window.location.href.indexOf("hangingprotocolId=mprAnd3DVolumeViewport")>-1&&(x>1||y>1)){*/}
            {/*                uiNotificationService.show({*/}
            {/*                    title: '切换布局失败',*/}
            {/*                    message:*/}
            {/*                        '在3D状态下，不支持2*2以上的布局切换.',*/}
            {/*                    type: 'error',*/}
            {/*                    duration: 4000,*/}
            {/*                });*/}
            {/*            }else{*/}
            {/*                onSelection({*/}
            {/*                    numRows: y + 1,*/}
            {/*                    numCols: x + 1,*/}
            {/*                });*/}
            {/*            }*/}
            {/*        }}*/}
            {/*        onMouseEnter={() => setHoveredIndex(index)}*/}
            {/*        onMouseLeave={() => setHoveredIndex(-1)}*/}
            {/*    ></div>*/}
            {/*))}*/}
            {/*</div>*/}
            <div style={{
                backgroundColor: '#323232',
                textAlign: 'left',
                color: '#FFF',
                paddingLeft: '5px',
                height: '28px',
                fontWeight: 400,
                display: 'flex',
                alignItems: 'center'
            }}>
                <span style={{paddingTop: '2px'}}>窗口模式：</span>
                <Switch
                    checkedChildren="序列布局"
                    unCheckedChildren="图像布局"
                    size="small"
                    checked={window.config.layoutFlag}
                    onChange={onChange}
                    style={{fontWeight: '400'}}
                />
            </div>
        </div>

    );
}

LayoutSelector.defaultProps = {
    onSelection: () => {
    },
    columns: 3,
    rows: 3
};

LayoutSelector.propTypes = {
    onSelection: PropTypes.func.isRequired,
    columns: PropTypes.number.isRequired,
    rows: PropTypes.number.isRequired,
};

export default LayoutSelector;
