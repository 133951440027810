import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IconButton, Icon, Tooltip } from '../';
import { useState } from 'react';
import translation from '../../../../i18n/src/locales/zh/Buttons.json';
const ToolbarSelect = ({
  type = 'tool',
  id,
  icon,
  label,
  commands,
  onInteraction,
  dropdownContent,
  //
  isActive: _isActive,
  className,
  bState = {},
  ...rest
  //
}) => {
  const { primaryToolId } = bState;
  const isActive = _isActive || (type === 'tool' && id === primaryToolId);
  const classes = {
    tool: isActive
      ? 'text-black'
      : 'text-common-bright hover:!bg-primary-dark hover:text-primary-light',
    toggle: isActive
      ? '!text-[#348CFD]'
      : 'text-common-bright hover:!bg-primary-dark hover:text-primary-light',
    action: isActive
      ? 'text-black'
      : 'text-common-bright hover:!bg-primary-dark hover:text-primary-light',
  };

  const bgClasses = {
    toggle: isActive && 'bg-transparent',
  };

  const activeClass = isActive ? 'active' : '';
  const shouldShowDropdown = !!isActive && !!dropdownContent;
  const iconEl = icon ? (
    <Icon name={icon} />
  ) : (
    <div>{label || 'Missing icon and label'}</div>
  );
  const { t } = useTranslation('Buttons');
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div key={id}>
      <Tooltip
        isSticky={shouldShowDropdown}
        content={shouldShowDropdown ? dropdownContent : label}
        tight={shouldShowDropdown}
      >
        {window.layoutFlag ? (
          <IconButton
            variant={isActive ? 'contained' : 'text'}
            bgColor={bgClasses[type]}
            size="toolbar"
            className={classnames(activeClass, classes[type], className)}
            onClick={() => {
              onInteraction({
                itemId: id,
                interactionType: type,
                commands,
              });
            }}
            name={label}
            key={id}
            id={id}
            {...rest}
          >
            {iconEl}
          </IconButton>
        ) : (
          <div
            style={{
              width: id == 'Layout' ? '127.5px' : '110.5px',
              fontSize: '12px',
              height: '42px',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: isHovered ? 'rgb(50 50 50)' : 'rgb(50 50 50)',
              borderRadius: translation[id] == '网格序列布局' ? '5px' : null,
              paddingLeft: '8px',
            }}
            onMouseEnter={() => setIsHovered(true)} // 鼠标移入时触发事件
            onMouseLeave={() => setIsHovered(false)} // 鼠标移出时触发事件
            onClick={() => {
              onInteraction({
                itemId: id,
                interactionType: type,
                commands,
              });
            }}
          >
            <span
              style={{
                width: translation[id] == '网格序列布局' ? '90%' : null,
                textAlign: 'left',
              }}
            >
              {translation[id]}
            </span>

            {translation[id] == '网格序列布局' ? (
              <Icon width="15px" height="15px" name="chevron-down" />
            ) : (
              undefined
            )}
          </div>
        )}
      </Tooltip>
    </div>
  );
};

ToolbarSelect.defaultProps = {
  dropdownContent: null,
  isActive: false,
  type: 'action',
};

ToolbarSelect.propTypes = {
  /* Influences background/hover styling */
  type: PropTypes.oneOf(['action', 'toggle', 'tool']),
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onInteraction: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  /** Tooltip content can be replaced for a customized content by passing a node to this value. */
  dropdownContent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default ToolbarSelect;
