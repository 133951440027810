import {
    ScaleOverlayTool,
} from '@cornerstonejs/tools';
import {Enums} from "@cornerstonejs/core";

function defaultReferenceLineColor() {
    return 'rgb(0, 200, 0)';
}

class ScaleOverlayNewTool extends ScaleOverlayTool {
    constructor(toolProps, defaultToolProps) {
        toolProps.configuration = {
            scaleLocation: 'bottom', // 设置标尺位置
            color: 'red', // 自定义颜色
            scaleSize: 50, // 固定长度（以mm为单位）
        }
        super(toolProps, defaultToolProps);
    }
}

ScaleOverlayNewTool.toolName = 'ScaleOverlay';
export default ScaleOverlayNewTool;
