import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.css';

const UploadAndDownloadMenuItem = ({title, subtitle, isSelected, index}) => (
    <>

        {window.layoutFlag ? (


            <div>

                <div
                    className={classNames(
                        'flex flex-row items-center p-3 h-8 w-full bg-primary-dark',
                        isSelected && 'bg-primary-dark'
                    )}
                    style={{padding:'10px 0px'}}
                >
                    <span className=" mr-2 text-base whitespace-nowrap" style={{width:'120px',textAlign:'left',paddingLeft:'10px'}}>{title}</span>
                    <span className="flex-1  font-light text-sm whitespace-nowrap"></span>

                    <span className=" ml-5 text-sm whitespace-nowrap" style={{marginRight:'10px',width:'20px',fontSize: '16px'}}>
                        {
                            (window.config.FitWindow && index == 0)
                            || (window.config.FitImage && index == 1)
                            || (window.config.PositionSyn && index == 2)
                            || (window.config.IndexSyn && index == 3)
                            || (window.windowLevel && index == 4)
                            || (window.markingFlag && index == 5)
                            || (window.config.StackScroll && index == 6)
                            // || (window.config.ReferenceLines && index == 7)
                            || (window.config.FreeRotation && index == 7)
                            || (window.config.Magnify && index == 11) ? '✔' : ''} </span>
                </div>
              {index == 1 || index == 3|| index == 5|| index == 6|| index == 10|| index == 11? (
                  <div style={{height:'1px',borderTop:'1px solid #fff'}}>
                  </div> ): (null)}
            </div>


        ) : (

            <div
                className={classNames(
                    'flex flex-row items-center p-3 h-8 w-full bg-primary-dark',
                    isSelected && 'bg-primary-dark'
                )}
            >
                <span className=" mr-2 text-base whitespace-nowrap">{title}</span>
                <span className="flex-1  font-light text-sm whitespace-nowrap">
        {subtitle}
      </span>
                <span className=" ml-5 text-sm whitespace-nowrap">{index + 1}</span>
            </div>
        )}


    </>
);

UploadAndDownloadMenuItem.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
};

export default UploadAndDownloadMenuItem;
