export default {
    1: {
        description: 'Soft tissue', window: '清除颜色',
        level: [
            0, 0, 0, 0,    // Black
            255, 1, 1, 1   // White
        ]
    },
    2: {
        description: 'Lung', window: '热图',
        level: [
            0, 0, 0, 0,  // 紫色
            128, 1, 0, 0,  // 黄色
            242, 1, 1, 0.6 , // 深红色
            255, 1, 1,0,  // 红色
        ]
    },
    3: {
        description: 'Liver', window: '彩虹',
        level: [
            0, 0, 0, 0,  // 紫色
            42, 0, 0, 0.5,  // 蓝色
            85, 0, 1, 0,  // 绿色
            128, 1, 1, 0,  // 黄色
            170, 1, 0, 0,  // 红色
            212, 0.5, 0, 0  // 深红色
        ]
    },
    4: {
        description: 'Bone', window: '冷热',
        level: [
            0,   0,   0,   0,   // Lowest value: Black
            42, 0, 0, 0.5,  // 蓝色
            128, 1, 1, 0,  // 黄色
            170, 1, 0, 0,  // 红色
        ]
    },
    5: {
        description: 'Brain', window: '灰度',
        level: [
            0,   0,   0,   0,   // Lowest value: Black
            160, 1, 1, 1  // Highest value: White
        ]
    },
    6: {
        description: 'Trest', window: '地形',
        level: [
            0,   0,   0,   0,   // Lowest value: Black
            85, 0, 1, 0,  // 绿色
            128, 1, 1, 0,  // 黄色
            170, 1, 0, 0,  // 红色
        ]
    },
    7: {
        description: 'Empty1', window: '火山',
        level: [
            0, 0, 0, 0,   // Dark Gray (Background)
            42, 0, 0, 0.8,  // 蓝色
            170, 1, 0.3, 0,  // 红色
            212, 0.5, 0.5, 0  // 深红色
        ]
    },
    8: {
        description: 'Empty2', window: 'Empty2',
        level: [
            0, 0, 0, 0,   // Black
            55, 255, 0, 255, // Yellow
            255, 0, 0, 255, // Red
            255, 0, 255, 255// Magenta
        ]
    },
    9: {
        description: 'Empty3', window: 'Empty3',
        level: [
            0, 0, 0, 0,   // Black
            55, 255, 0, 255, // Yellow
            255, 0, 0, 255, // Red
            255, 0, 255, 255// Magenta
        ]
    },
    10: {
        description: 'Empty4', window: 'Empty4',
        level: [
            0, 0, 0, 0,   // Black
            55, 255, 0, 255, // Yellow
            255, 0, 0, 255, // Red
            255, 0, 255, 255// Magenta
        ]
    },
};
