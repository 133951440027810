import React, { ChangeEvent, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { Icon, Tooltip, InputRange } from '..';
import { utilities as csToolsUtils } from '@cornerstonejs/tools';
import { Switch } from 'antd';;
import {useSearchParams} from '@hooks';
import {getEnabledElements} from '@cornerstonejs/core';
import { useCine } from '@ohif/ui';
import './CinePlayer.css';
import classNames from 'classnames';
import NumberFormat = Intl.NumberFormat;

export type CinePlayerProps = {
  className: string;
  isPlaying: boolean;
  isPlayType: number;
  minFrameRate: number;
  maxFrameRate: number;
  stepFrameRate: number;
  frameRate: number;
  onFrameRateChange: (value: number) => void;
  onPlayPauseChange: (value: boolean) => void;
  onTypeRateChange: (value: number) => void;
  onClose: () => void;
  servicesManager?: any;
  viewportIndex?: any;
};

const fpsButtonClassNames =
  'cursor-pointer  active:text-primary-light w-4 flex items-center justify-center';

const CinePlayer: React.FC<CinePlayerProps> = ({
  className,
  isPlaying,
  isPlayType,
  minFrameRate,
  maxFrameRate,
  stepFrameRate,
  frameRate: defaultFrameRate,
  onFrameRateChange,
  onPlayPauseChange,
  onTypeRateChange,
  onClose,
  servicesManager,
  viewportIndex,
}) => {
  const {
    cineService,
    cornerstoneViewportService,
    viewportGridService,
  } = servicesManager.services;
  const [frameRate, setFrameRate] = useState(defaultFrameRate);
  const debouncedSetFrameRate = debounce(onFrameRateChange, 300);
  const [{ isCineEnabled, cines }] = useCine();
  let num = 24;
  const getPlayPauseIconName = () => (isPlaying ? 'icon-pause' : 'icon-play');

  const handleSetFrameRate = (frameRate: number) => {
    if (frameRate < minFrameRate || frameRate > maxFrameRate) {
      return;
    }
    setFrameRate(frameRate);
    num = frameRate;
    debouncedSetFrameRate(frameRate);
  };
  // let viewportArray = []; // 存储视口数组
  // const synchronous = (length, imageIndexs, viewport1, e) => {
  //   console.log(imageIndexs);
  //   if (length !== 1) {
  //     viewportArray.push({ length: length, viewport1, imageIndexs });
  //     viewportArray.sort((a, b) => b.length - a.length); // 根据长度降序排序
  //     if (viewportArray.length > 1) {
  //       const longestLength = viewportArray[0].length; // 长度最长的数组的长度
  //       const shortestLength = viewportArray[viewportArray.length - 1].length; // 长度最短的数组的长度

  //       viewportArray.forEach(v => {
  //         const currentLength = v.length;
  //         if (currentLength === longestLength) {
  //           // 长度长的数组
  //           if (e == 'left') {
  //             csToolsUtils.jumpToSlice(v.viewport1.element, {
  //               imageIndex:
  //                 v.imageIndexs - Math.ceil(longestLength / shortestLength),
  //               debounceLoading: true,
  //             });
  //           } else {
  //             csToolsUtils.jumpToSlice(v.viewport1.element, {
  //               imageIndex:
  //                 v.imageIndexs + Math.ceil(longestLength / shortestLength),
  //               debounceLoading: true,
  //             });
  //           }
  //         } else {
  //           // 长度短的数组加1
  //           if (e == 'left') {
  //             csToolsUtils.jumpToSlice(v.viewport1.element, {
  //               imageIndex: v.imageIndexs - 1,
  //               debounceLoading: true,
  //             });
  //           } else {
  //             csToolsUtils.jumpToSlice(v.viewport1.element, {
  //               imageIndex: v.imageIndexs + 1,
  //               debounceLoading: true,
  //             });
  //           }
  //         }
  //       });
  //     }
  //   }
  //   // 打印修改后的结果
  //   console.log(viewportArray);
  // };

  const next = e => {
    const viewportInfo = cornerstoneViewportService.getViewportInfoByIndex(
      viewportIndex
    );

    const viewportId = viewportInfo.getViewportId();
    const viewport = cornerstoneViewportService.getCornerstoneViewport(
      viewportId
    );
    const imageIndex = viewport.getCurrentImageIdIndex();
    console.log(imageIndex);
    if (e == 'left') {
      csToolsUtils.jumpToSlice(viewport.element, {
        imageIndex: imageIndex - 1,
        debounceLoading: true,
      });
    } else {
      csToolsUtils.jumpToSlice(viewport.element, {
        imageIndex: imageIndex + 1,
        debounceLoading: true,
      });
    }
  };
  const onChange = (checked: boolean) => {
    let Elements = getEnabledElements()
    let num = 0
    if (Elements.length>4 && Elements[0].viewport.type == 'volume3d'){
      Elements = Elements.slice(-4);
      num = Elements.length - 4
    }
    if (!checked) {
      onTypeRateChange(1);
      for (const key in cines) {
        if (cines.hasOwnProperty(key)) {
          const cine = cines[key];
          if (Elements[Number(cine.id)] && Elements[Number(cine.id)].viewport.type == 'volume3d'){

          }else{
            cineService.setCine({ id: Number(cine.id + num), isPlaying: false, isPlayType: 1 });
          }
        }
      }
    } else {
      cineService.setType({ id: 1, isPlayType: 2, isPlaying: false });
      onTypeRateChange(2);
      for (const key in cines) {
        if (cines.hasOwnProperty(key)) {
          const cine = cines[key];
          if (Elements[Number(cine.id)] && Elements[Number(cine.id)].viewport.type == 'volume3d'){

          }else{
            cineService.setCine({ id: Number(cine.id + num), isPlaying: true, isPlayType: 2 });
          }
        }
      }
    }
  };
  return (
    <div
      className={classNames(
        className,
        'select-none flex items-center gap-2 px-2 py-2 rounded border border-secondary-light/60 '
      )}
      style={{
        border: '1px solid rgba(37,37,37,1)',
        width: '100%',
        borderTop: window.layoutFlag ? null : '1px solid',
        borderBottom: window.layoutFlag ? null :  '1px solid',
        height: window.layoutFlag ? '46px' : '40px',
      }}
    >
      {/* <a onClick={() => next('left')} className="cursor-pointer text-white">
        左
      </a>leftNext */}
      <Icon
        name={'leftNext'}
        className="cursor-pointer text-white active:text-primary-light "
        onClick={() => next('left')}
        style={{ width: 20 }}
      />
      <Icon
        name={getPlayPauseIconName()}
        className="cursor-pointer text-white active:text-primary-light "
        onClick={() => onPlayPauseChange(!isPlaying)}
      />
      <Icon
        name={'rightNext'}
        className="cursor-pointer text-white active:text-primary-light"
        onClick={() => next('right')}
        style={{ width: 20 }}
      />
      <span
        style={{
          backgroundColor: 'rgb(50, 50, 50)',
          width: '81px',
          paddingBottom: '2.5px',
        }}
      >
        <Switch
          checkedChildren="全部停止"
          unCheckedChildren="全部播放"
          onChange={onChange}
          style={{ fontWeight: '400' }}
        />
      </span>
      <Tooltip
        position="bottom"
        className="group/fps cine-fps-range-tooltip"
        tight={true}
        content={
          <InputRange
            containerClassName="h-9 px-2"
            inputClassName="w-40"
            value={frameRate}
            minValue={minFrameRate}
            maxValue={maxFrameRate}
            step={stepFrameRate}
            onChange={handleSetFrameRate}
            showLabel={false}
          />
        }
      >
        <div
          style={{ borderColor: 'rgba(37,37,37,1)', paddingLeft: '0px' }}
          className="border  flex h-6 items-stretch rounded gap-1"
        >
          <div
            className={`${fpsButtonClassNames} rounded-l`}
            onClick={() => handleSetFrameRate(frameRate - 1)}
          >
            <Icon name="arrow-left-small" />
          </div>
          <div
            className="w-11 text-sm text-white text-center leading-[22px]"
            style={{ fontWeight: '450' }}
          >
            {`${frameRate} FPS`}
          </div>
          <div
            className={`${fpsButtonClassNames} rounded-r`}
            onClick={() => handleSetFrameRate(frameRate + 1)}
          >
            <Icon name="arrow-right-small" />
          </div>
        </div>
      </Tooltip>
      {/* <Icon
        name="icon-close"
        className="cursor-pointer text-primary-active active:text-primary-light hover:bg-customblue-300 hover:rounded"
        onClick={onClose}
      /> */}
    </div>
  );
};

const noop = () => {};

CinePlayer.defaultProps = {
  isPlaying: false,
  isPlayType: 1,
  minFrameRate: 1,
  maxFrameRate: 90,
  stepFrameRate: 1,
  frameRate: 24,
  onPlayPauseChange: noop,
  onFrameRateChange: noop,
  onTypeRateChange: noop,
  onClose: noop,
};

CinePlayer.propTypes = {
  /** Minimum value for range slider */
  minFrameRate: PropTypes.number.isRequired,
  /** Maximum value for range slider */
  maxFrameRate: PropTypes.number.isRequired,
  /** Increment range slider can "step" in either direction */
  stepFrameRate: PropTypes.number.isRequired,
  frameRate: PropTypes.number.isRequired,
  /** 'true' if playing, 'false' if paused */
  isPlaying: PropTypes.bool.isRequired,
  onPlayPauseChange: PropTypes.func,
  onFrameRateChange: PropTypes.func,
  onTypeRateChange: PropTypes.func,
  onClose: PropTypes.func,
};

export default CinePlayer;
