export default {
  1: { description: '1', window: '1', level: 'UploadImage' },
  2: { description: '2', window: '2', level: 'DownloadVideo' },
  3: { description: '3', window: '3', level: 'DownloadVideo' },
  4: { description: '4', window: '4', level: 'DownloadVideo' },
  5: { description: '5', window: '5', level: 'DownloadVideo' },
  6: { description: '6', window: '6', level: 'DownloadVideo' },
  7: { description: '7', window: '7', level: 'DownloadVideo' },
  8: { description: '8', window: '8', level: 'DownloadVideo' },
  9: { description: '9', window: '9', level: 'DownloadVideo' },
  10: { description: '10', window: '10', level: 'StackScroll' },
  11: { description: '11', window: '11', level: 'DownloadVideo' },
  12: { description: '12', window: '12', level: 'DownloadVideo' },
  13: { description: '13', window: '13', level: 'DownloadVideo' },
  14: { description: '14', window: '14', level: 'DownloadVideo' },
  15: { description: '15', window: '15', level: 'DownloadVideo' },
  16: { description: '16', window: '16', level: 'DownloadVideo' },
  17: { description: '17', window: '17', level: 'DownloadVideo' },
  18: { description: '18', window: '18', level: 'DownloadVideo' },
};
