export default [
  'Length',
  'EllipticalROI',
  'CircleROI',
  'Bidirectional',
  'ArrowAnnotate',
  'Angle',
  'Freehandline',
  'CobbAngle',
  'Probe',
  'RectangleROI',
  'PlanarFreehandROI',
];
