import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDrop } from 'react-dnd';
import {getEnabledElements} from "@cornerstonejs/core";
import {servicesManager} from "@ohif/viewer/src/App";
import {utilities as csToolsUtils} from "@cornerstonejs/tools";

// NOTE: If we found a way to make `useDrop` conditional,
// Or we provided a HOC of this component, we could provide
// this UI without the DragAndDropContext dependency.
function ViewportPane({
  children,
  className,
  customStyle,
  isActive,
  isLineActive,
  viewportIndex,
  onDrop,
  onDoubleClick,
  onInteraction,
  acceptDropsFor,
}) {
  let dropElement = null;
  const { toolbarService,viewportGridService,displaySetService,uiNotificationService} = servicesManager.services;
  const [{ isHovered, isHighlighted }, drop] = useDrop({
    accept: acceptDropsFor,
    // TODO: pass in as prop?
    drop: (droppedItem, monitor) => {
      const canDrop = monitor.canDrop();
      const isOver = monitor.isOver();
      console.log("dee")
      if (canDrop && isOver && onDrop) {
        onInteractionHandler();
        onDrop(droppedItem);
        if (window.config.layoutFlag){
          let props = []
          const targetDisplaySet = displaySetService.getDisplaySetByUID(droppedItem.displaySetInstanceUID)
          if (targetDisplaySet.images.length == 1){
            uiNotificationService.show({
              title: '布局切换失败',
              message:
                  '该功能为多图像平铺布局，请选择（切换）到拥有多张图像的序列再进行使用。',
              type: 'error',
              duration: 6000,
            });
            return
          }
          toolbarService.setIndexSyn(true);
          toolbarService.setPositionSyn(false);
          const state = viewportGridService.getState();
          state.viewports.forEach((viewport,index) => {
            let prop = {
              viewportIndex: index,
              displaySetInstanceUIDs: [droppedItem.displaySetInstanceUID],
            }
            props.push(prop)
          })
          viewportGridService.setLayoutDisplaySetsForViewports(props,'');
        }
      }
    },
    // Monitor, and collect props; returned as values by `useDrop`
    collect: monitor => ({
      isHighlighted: monitor.canDrop(),
      isHovered: monitor.isOver(),
    }),
  });

  const focus = () => {
    if (dropElement) {
      dropElement.focus();
    }
  };

  const onMouseEnterHandler = event => {
    console.log("dee")
   if (window.group){
     focus();
     onInteraction(event);
   }
  };

  const onInteractionHandler = event => {
    console.log("dee")
    if (!window.markingFlag) {
      window.group = false
    }
    focus();
    onInteraction(event);
    window.config.viewportIndex = viewportIndex

  };

  const refHandler = element => {
    drop(element);
    dropElement = element;
  };
  return (
    <div
      ref={refHandler}
      // onInteractionHandler...
      // https://reactjs.org/docs/events.html#mouse-events
      // https://stackoverflow.com/questions/8378243/catch-scrolling-event-on-overflowhidden-element
      onMouseDown={onInteractionHandler}
      onDoubleClick={onDoubleClick}
      onMouseEnter={onMouseEnterHandler} // 添加鼠标移入事件处理程序
      onClick={onInteractionHandler}
      onScroll={onInteractionHandler}
      onWheel={onInteractionHandler}
      className={classnames(
        'w-full h-full overflow-hidden transition duration-300 group',
        {
          // 'border-2': isActive,
          // 'border-2 border-transparent': !isActive,
        },
        className
      )}
      style={{
        ...customStyle,
      }}
    >
      <div
        className={classnames(
          ' w-full h-full overflow-hidden group-hover:border-transparent',
          {
            'border border-transparent': isActive,
            'border border-secondary-light': !isActive,
          },
          className
        )}
        style={{
          border: !(window.config.viewportIndex == viewportIndex)
            ? '1px solid #323232'
            : customStyle.width == '99.98%' && customStyle.height == '100%'
            ? '#000'
            : '1px dashed rgb(242 236 9)',
        }}
      >
        {children}
      </div>
    </div>
  );
}

ViewportPane.propTypes = {
  /** The ViewportComp */
  children: PropTypes.node.isRequired,
  /** Classes to append to container */
  className: PropTypes.string,
  /** Bool to show active styling */
  isActive: PropTypes.bool.isRequired,
  isLineActive: PropTypes.bool.isRequired,
  viewportIndex: PropTypes.any,
  /** Indicates drag items we should accept for drops */
  acceptDropsFor: PropTypes.string.isRequired,
  /** Function that handles drop events */
  onDrop: PropTypes.func.isRequired,
  /** Called when the viewportPane is interacted with by the user */
  onInteraction: PropTypes.func.isRequired,
  /** Executed when the pane is double clicked */
  onDoubleClick: PropTypes.func,
};

const noop = () => {};

ViewportPane.defaultProps = {
  onInteraction: noop,
};

export default ViewportPane;
