import SUPPORTED_TOOLS from './constants/supportedTools';
import getSOPInstanceAttributes from './utils/getSOPInstanceAttributes';
import { displaySetService, utils } from '@ohif/core';
import { uniqBy } from 'lodash';

const Length = {
  toAnnotation: measurement => {},

  /**
   * Maps cornerstone annotation event data to measurement service format.
   *
   * @param {Object} cornerstone Cornerstone event data
   * @return {Measurement} Measurement instance
   */
  toMeasurement: (
    csToolsEventDetail,
    displaySetService,
    cornerstoneViewportService,
    getValueTypeFromToolType,
    measurementService,
    servicesManager
  ) => {
    const { annotation, viewportId } = csToolsEventDetail;
    const { metadata, data, annotationUID } = annotation;
    const measurements = measurementService.getMeasurements();
    const { toolbarService } = servicesManager.services;
    if (!metadata || !data) {
      console.warn('Length tool: Missing metadata or data');
      return null;
    }

    let name = toolbarService.getActiveTools()
      ? toolbarService.getActiveTools()[0]
      : '';

    const { toolName, referencedImageId, FrameOfReferenceUID } = metadata;
    const validToolType = SUPPORTED_TOOLS.includes(toolName);

    if (!validToolType) {
      throw new Error('Tool not supported');
    }

    const {
      SOPInstanceUID,
      SeriesInstanceUID,
      StudyInstanceUID,
    } = getSOPInstanceAttributes(
      referencedImageId,
      cornerstoneViewportService,
      viewportId
    );

    let displaySet;

    if (SOPInstanceUID) {
      displaySet = displaySetService.getDisplaySetForSOPInstanceUID(
        SOPInstanceUID,
        SeriesInstanceUID
      );
    } else {
      displaySet = displaySetService.getDisplaySetsForSeries(SeriesInstanceUID);
    }

    const { points } = data.handles;

    const mappedAnnotations = getMappedAnnotations(
      annotation,
      displaySetService,
      annotationUID,
      measurements,
      name,
      toolbarService
    );

    const displayText = getDisplayText(mappedAnnotations, displaySet);
    const getReport = () =>
      _getReport(mappedAnnotations, points, FrameOfReferenceUID);
    let length = '';
    Object.keys(data.cachedStats).forEach(targetId => {
      length = data.cachedStats[targetId].length;
    });
    return {
      uid: annotationUID,
      SOPInstanceUID,
      FrameOfReferenceUID,
      points,
      metadata,
      referenceSeriesUID: SeriesInstanceUID,
      referenceStudyUID: StudyInstanceUID,
      frameNumber: mappedAnnotations[0]?.frameNumber || 1,
      toolName: metadata.toolName,
      displaySetInstanceUID: displaySet.displaySetInstanceUID,
      label: data.label,
      displayText: displayText,
      length,
      data: data.cachedStats,
      type: getValueTypeFromToolType(toolName),
      getReport,
    };
  },
};

let arr1 = [];
let arr2 = [];

function getMappedAnnotations(
  annotation,
  displaySetService,
  annotationUID,
  measurements,
  name,
  toolbarService
) {
  const { metadata, data } = annotation;
  const { cachedStats } = data;
  const { referencedImageId } = metadata;
  const targets = Object.keys(cachedStats);
  if (!targets.length) {
    return [];
  }
  if (measurements.length === 1) {
    arr1 = [];
    arr2 = [];
  }
  let arr;
  if (name === 'Length') {
    arr = arr1;
  } else {
    arr = arr2;
  }

  arr.push({
    annotationUID: annotationUID,
    name: name,
  });

  let list = uniqBy(arr, 'annotationUID');
  list.forEach((v, index) => {
    v.key = index;
  });

  const activateTool = () => {
    toolbarService.recordInteraction({
      groupId: 'WindowLevel',
      itemId: 'WindowLevel',
      interactionType: 'tool',
      commands: [
        {
          commandName: 'setToolActive',
          commandOptions: {
            toolName: 'WindowLevel',
          },
          context: 'CORNERSTONE',
        },
      ],
    });
  };

  const annotations = [];
  // let value;

  measurements.forEach((item, index) => {
    Object.keys(item.data).forEach(targetId => {
      list.forEach((v, listIndex) => {
        item.data[targetId].length = parseFloat(
          item.data[targetId].length.toFixed(2)
        );
        if (v.annotationUID === item.uid) {
          if ((index + 1) % 2 === 0) {
            // 计算每两组测量之间的值
            item.value =
              measurements[index].data[targetId].length /
              measurements[index - 1].data[targetId].length;
            if (
              measurements[index].data[targetId].length <
              measurements[index - 1].data[targetId].length
            ) {
              item.value =
                measurements[index].data[targetId].length /
                measurements[index - 1].data[targetId].length;
            } else {
              item.value =
                measurements[index - 1].data[targetId].length /
                measurements[index].data[targetId].length;
            }

            item.value = parseFloat(item.value.toFixed(4));
            measurements[index - 1].value = measurements[index].value;
            item.auto =
              measurements[index].data[targetId].length >
              measurements[index - 1].data[targetId].length
                ? ' * '
                : ' / ';
            item.auto1 =
              measurements[index].data[targetId].length <
              measurements[index - 1].data[targetId].length
                ? ' * '
                : ' / ';
            measurements[index - 1].auto1 = measurements[index].auto1;
            console.log(index, 'list', list);
            if (
              name !== 'Length' &&
              list.length % 2 !== 1 &&
              index === measurements.length - 1
            ) {
              // 在每两条线之后调用 activateTool 结束一个循环
              activateTool();
            }
          }

          if (name === 'Length') {
            item.data[targetId].unit = `mm ${v.key + 1}`;
          } else {
            if ((index + 1) % 2 === 0) {
              item.data[targetId].unit = `mm ${item.auto} ${item.value}`;
              measurements[index - 1].data[
                targetId
              ].unit = `mm ${item.auto1} ${item.value}`;
            } else {
              item.data[targetId].unit = `mm  ${
                item.value ? item.auto1 + item.value : ''
              }`;
            }
          }
        }
      });
    });
  });

  Object.keys(cachedStats).forEach(targetId => {
    const targetStats = cachedStats[targetId];
    const { length } = targetStats;

    if (!referencedImageId) {
      throw new Error(
        'Non-acquisition plane measurement mapping not supported'
      );
    }

    const {
      SOPInstanceUID,
      SeriesInstanceUID,
      frameNumber,
    } = getSOPInstanceAttributes(referencedImageId);

    const displaySet = displaySetService.getDisplaySetForSOPInstanceUID(
      SOPInstanceUID,
      SeriesInstanceUID,
      frameNumber
    );

    const { SeriesNumber } = displaySet;
    // const { length } = targetStats;
    const unit = 'mm';

    annotations.push({
      SeriesInstanceUID,
      SOPInstanceUID,
      SeriesNumber,
      frameNumber,
      unit,
      length,
    });
  });

  return annotations;
}
/*
This function is used to convert the measurement data to a format that is
suitable for the report generation (e.g. for the csv report). The report
returns a list of columns and corresponding values.
*/
function _getReport(mappedAnnotations, points, FrameOfReferenceUID) {
  const columns = [];
  const values = [];

  // Add Type
  columns.push('AnnotationType');
  values.push('Cornerstone:Length');

  mappedAnnotations.forEach(annotation => {
    const { length } = annotation;
    columns.push(`Length (mm)`);
    values.push(length);
  });

  if (FrameOfReferenceUID) {
    columns.push('FrameOfReferenceUID');
    values.push(FrameOfReferenceUID);
  }

  if (points) {
    columns.push('points');
    // points has the form of [[x1, y1, z1], [x2, y2, z2], ...]
    // convert it to string of [[x1 y1 z1];[x2 y2 z2];...]
    // so that it can be used in the csv report
    values.push(points.map(p => p.join(' ')).join(';'));
  }

  return {
    columns,
    values,
  };
}

function getDisplayText(mappedAnnotations, displaySet) {
  if (!mappedAnnotations || !mappedAnnotations.length) {
    return '';
  }

  const displayText = [];

  // Area is the same for all series
  const {
    length,
    SeriesNumber,
    SOPInstanceUID,
    frameNumber,
  } = mappedAnnotations[0];

  const instance = displaySet.images.find(
    image => image.SOPInstanceUID === SOPInstanceUID
  );

  let InstanceNumber;
  if (instance) {
    InstanceNumber = instance.InstanceNumber;
  }

  const instanceText = InstanceNumber ? ` I: ${InstanceNumber}` : '';
  const frameText = displaySet.isMultiFrame ? ` F: ${frameNumber}` : '';

  const roundedLength = utils.roundNumber(length, 2);
  displayText.push(
    `${roundedLength} mm (S: ${SeriesNumber}${instanceText}${frameText})`
  );

  return displayText;
}

export default Length;
