import {
  CONSTANTS,
  setVolumesForViewports,
  getEnabledElement,
  StackViewport,
  VolumeViewport,
  Types,
  Enums as EnumsType,
  utilities as csUtils,
  getEnabledElements,
} from '@cornerstonejs/core';
import {
  ToolGroupManager,
  Enums,
  utilities as cstUtils,
  ReferenceLinesTool,
  annotation,
  default as cornerstoneTools,
} from '@cornerstonejs/tools';
import { Types as OhifTypes } from '@ohif/core';

const { removeAllAnnotations } = annotation.state;

import CornerstoneViewportDownloadForm from './utils/CornerstoneViewportDownloadForm';
import callInputDialog from './utils/callInputDialog';
import toggleStackImageSync from './utils/stackSync/toggleStackImageSync';
import { getFirstAnnotationSelected } from './utils/measurementServiceMappings/utils/selection';
import getActiveViewportEnabledElement from './utils/getActiveViewportEnabledElement';
import { CornerstoneServices } from './types';
import cornerstone from 'cornerstone';
import IStackViewport from '@cornerstonejs/core';
import OHIF from '@ohif/core';
import { imageLoader } from '@cornerstonejs/core';
import { Button, Checkbox, Col, Modal, Radio, Row } from 'antd';
import JSZip from 'jszip';
import React, { useState } from 'react';
import FileSaver from 'file-saver';
import tsWhammy from 'ts-whammy';
import vtkLineFilter from '@kitware/vtk.js/Filters/General/LineFilter';

function commandsModule({
  servicesManager,
  commandsManager,
}: OhifTypes.Extensions.ExtensionParams): OhifTypes.Extensions.CommandsModule {
  const {
    viewportGridService,
    toolGroupService,
    cineService,
    toolbarService,
    uiDialogService,
    cornerstoneViewportService,
    displaySetService,
    uiNotificationService,
    measurementService,
  } = servicesManager.services as CornerstoneServices;
  const { measurementServiceSource } = this;

  const openVideoModal = () => {
    let value = 'webm';
    // get actor from the viewport
    const enabledElement = getActiveViewportEnabledElement(viewportGridService);
    // const renderingEngine = cornerstoneViewportService.getRenderingEngine();
    const viewport = enabledElement.viewport;
    const imageIds = viewport.imageIds;
    if (imageIds.length < 10) {
      uiNotificationService.show({
        title: '生成失败',
        message: '无法生成少于十张影像的视频',
        type: 'error',
        duration: 3000,
      });
      return;
    }
    const handleChange = e => {
      value = e.target.value;
    };
    const openModal = () => {
      Modal.confirm({
        title: '将当前序列图片转换为视频',
        content: (
          <div style={{ marginTop: '20px', height: '25vh' }}>
            <p
              style={{
                marginTop: '20px',
                marginBottom: '20px',
                fontSize: '15px',
                fontWeight: '600',
              }}
            >
              选择视频下载格式
            </p>
            <Radio.Group
              name="radiogroup"
              defaultValue={value}
              onChange={handleChange}
            >
              <Radio value={'webm'}>导出WEBM格式视频</Radio>
              <Radio value={'mp4'}>导出MP4格式视频</Radio>
              <Radio value={'avi'}>导出AVI格式视频</Radio>
            </Radio.Group>
          </div>
        ),
        closable: true,
        okText: '确定下载', // 更改确定按钮的文本
        cancelText: '取消下载', // 更改确定按钮的文本
        icon: null, // 禁用默认图标
        width: '40%', // 自定义宽度
        okButtonProps: {
          className: 'custom-ok-button', // 自定义类名
          style: { background: 'blue', borderColor: 'blue' }, // 自定义样式
        },
        onOk() {
          console.log(value);
          let title = '';
          let message = '';
          // 创建一个用于存储 canvas 的数组
          const canvases = [];
          const base64Imgs = [];
          const videoFrames = [];
          // 创建一个新的压缩包
          const zip = new JSZip();
          title = '下载视频';
          message = '转换当前序列的全部图像为视频中，请等待....';
          uiNotificationService.show({
            title: title,
            message: message,
            type: 'info',
            duration: 5000,
          });

          const num = imageIds.length;
          // 遍历 imageIds 并将每个图像添加到 canvases 数组中
          imageIds.forEach((imageId, index) => {
            imageLoader.loadAndCacheImage(imageId).then(image => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              canvas.width = image.width;
              canvas.height = image.height;
              const imageData = ctx.createImageData(image.width, image.height);
              const pixelData = image.getPixelData();

              for (let i = 0; i < pixelData.length; i++) {
                // 由于 pixelData 是单通道的灰度图像，所以 R、G、B 都使用相同的值
                imageData.data[i * 4] = pixelData[i];
                imageData.data[i * 4 + 1] = pixelData[i];
                imageData.data[i * 4 + 2] = pixelData[i];
                imageData.data[i * 4 + 3] = 255; // 设置透明度为不透明
              }
              ctx.putImageData(imageData, 0, 0);
              const dataUrl = canvas.toDataURL('image/png');

              videoFrames.push(
                ctx.getImageData(0, 0, canvas.width, canvas.height)
              );
              canvases.push(canvas);
              base64Imgs.push(dataUrl);

              if (base64Imgs.length == imageIds.length) {
                tsWhammy
                  .fixImageDataList(base64Imgs, {
                    width: 500,
                    height: 500,
                    backgroundColor: '#fff',
                  })
                  .then(arrs => {
                    let num = Math.ceil(imageIds.length / 10);
                    const blob2 = tsWhammy.fromImageArrayWithOptions(arrs, {
                      duration: num,
                    });
                    const videoUrl3 = URL.createObjectURL(blob2);
                    const a3 = document.createElement('a');
                    a3.href = videoUrl3;
                    a3.download =
                      'MedicalImagingVideo' + new Date() + '.' + value;
                    a3.click();
                  });
              }
            });
          });
        },
      });
    };
    openModal();
  };

  const openImgModal = () => {
    let value = 'jpg';
    const handleChange = e => {
      value = e.target.value;
    };
    const openModal = () => {
      Modal.confirm({
        title: '批量下载该序列所有图像',
        content: (
          <div style={{ marginTop: '20px', height: '25vh' }}>
            <p
              style={{
                marginTop: '20px',
                marginBottom: '20px',
                fontSize: '15px',
                fontWeight: '600',
              }}
            >
              选择图片下载格式1
            </p>
            <Radio.Group
              name="radiogroup"
              defaultValue={value}
              onChange={handleChange}
            >
              <Radio value={'jpg'}>下载JPG格式图像</Radio>
              <Radio value={'png'}>下载PNG格式图像</Radio>
              <Radio value={'jpeg'}>下载JPEG格式图像</Radio>
              <Radio value={'dcmCurrent'}>下载当前序列DCM格式图像</Radio>
              <Radio value={'dcmAll'}>下载所有序列DCM格式图像</Radio>
            </Radio.Group>
          </div>
        ),
        closable: true,
        okText: '确定下载', // 更改确定按钮的文本
        cancelText: '取消下载', // 更改确定按钮的文本
        icon: null, // 禁用默认图标
        width: '40%', // 自定义宽度
        okButtonProps: {
          className: 'custom-ok-button', // 自定义类名
          style: { background: 'blue', borderColor: 'blue' }, // 自定义样式
        },
        onOk() {
          // get actor from the viewport
          const enabledElement =
            getActiveViewportEnabledElement(viewportGridService);
          const viewport = enabledElement.viewport;
          // 创建一个用于存储 canvas 的数组
          const imageIds = viewport.imageIds;

          let secondsLeft = Math.ceil(imageIds.length / 10);
          const message = `下载当前序列的全部图像中,预计需要${secondsLeft}秒,请等待....`;
          uiNotificationService.show({
            title: '下载图片',
            message: message,
            type: 'info',
            duration: secondsLeft * 1000, // 通知持续1秒
          });
          // 创建一个新的压缩包
          const zip = new JSZip();

          const num = imageIds.length;

          let urls = [];
          // 遍历 imageIds 并将每个图像添加到 canvases 数组中
          imageIds.forEach((imageId, index) => {
            imageLoader.loadAndCacheImage(imageId).then(image => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              canvas.width = image.width;
              canvas.height = image.height;
              const imageData = ctx.createImageData(image.width, image.height);
              const pixelData = image.getPixelData();

              for (let i = 0; i < pixelData.length; i++) {
                // 由于 pixelData 是单通道的灰度图像，所以 R、G、B 都使用相同的值
                imageData.data[i * 4] = pixelData[i];
                imageData.data[i * 4 + 1] = pixelData[i];
                imageData.data[i * 4 + 2] = pixelData[i];
                imageData.data[i * 4 + 3] = 255; // 设置透明度为不透明
              }
              ctx.putImageData(imageData, 0, 0);
              if (value !== 'dcmCurrent' && value !== 'dcmAll') {
                const imgData = canvas.toDataURL('image/' + value);
                zip.file(
                  `image_${image.imageId.split('/')[12]}.${value}`,
                  imgData.split(',')[1],
                  { base64: true }
                );
                if (num - 1 == index) {
                  zip.generateAsync({ type: 'blob' }).then(content => {
                    // 下载压缩包
                    FileSaver.saveAs(content, 'myZipFile.zip');
                  });
                }
              } else {
                const imageUrl = imageIds[0].split('dicomweb')[1];
                const parts = imageUrl.split('/');
                const studyUID = parts[2];
                const seriesUID = parts[4];
                const objectUID = parts[6];
                console.log(parts);
                const webUrl =
                  window.config.dataSources[0].configuration.wadoRoot;

                const url =
                  webUrl +
                  '/studies/' +
                  studyUID +
                  '?accept=application%2Fzip&dicomdir=true';
                const hrefUrl =
                  webUrl +
                  '/studies/' +
                  studyUID +
                  '/series/' +
                  seriesUID +
                  '?accept=application%2Fzip&dicomdir=true';
                //let xhr = new XMLHttpRequest();
                console.log(value);
                const downloadLink = value == 'dcmCurrent' ? hrefUrl : url;

                // downloadLink.click();
                fetch(downloadLink, {
                  method: 'GET',
                  headers: window.token,
                })
                  .then(response => {
                    if (response.ok) {
                      response.blob().then(blob => {
                        // 处理文件下载
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'ZipFile';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                      });
                    } else {
                      throw new Error('请求失败');
                    }
                  })
                  .catch(error => {
                    console.error(error);
                  });
              }
            });
          });
        },
      });
    };
    openModal();
  };

  const radiographicPrintingModal = async flag => {
    let selectedImages = [];

    const enabledElement = getActiveViewportEnabledElement(viewportGridService);
    const viewport = enabledElement.viewport;
    // 创建一个用于存储 canvas 的数组
    const imageIds = viewport.imageIds;
    if (imageIds.length > 9) {
      uiNotificationService.show({
        title: '加载图像',
        message:
          '正在加载预打印图像序列，共' + imageIds.length + '张，请稍等..',
        type: 'info',
        duration: imageIds.length * 100 < 1000 ? 1000 : imageIds.length * 100, // 通知持续1秒
      });
    }

    // 遍历 imageIds 并将每个图像添加到 canvases 数组中
    for (let i = 0; i < imageIds.length; i++) {
      const image = await imageLoader.loadAndCacheImage(imageIds[i]);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = image.width;
      canvas.height = image.height;
      const imageData = ctx.createImageData(image.width, image.height);
      const pixelData = image.getPixelData();

      for (let i = 0; i < pixelData.length; i++) {
        // 由于 pixelData 是单通道的灰度图像，所以 R、G、B 都使用相同的值
        imageData.data[i * 4] = pixelData[i];
        imageData.data[i * 4 + 1] = pixelData[i];
        imageData.data[i * 4 + 2] = pixelData[i];
        imageData.data[i * 4 + 3] = 255; // 设置透明度为不透明
      }
      ctx.putImageData(imageData, 0, 0);
      const base64DataUrl = canvas.toDataURL('image/png');
      let arr = selectedImages;
      arr.push({ url: base64DataUrl, checked: true, id: i, image: image });
      selectedImages = arr;
    }
    // 处理选择图片的回调
    const handleImageSelect = (image, index) => {
      selectedImages[index].checked = !selectedImages[index].checked;
    };

    // 构建 DICOM C-STORE 请求
    function buildCStoreRequest(
      studyUID,
      seriesUID,
      objectUID,
      dicomImageData
    ) {
      // 创建 DICOM 数据集
      const dicomDataSet = { elements: [], byteArray: null };

      // 添加 DICOM 数据元素，注意这里的示例数据，需要根据具体情况修改
      dicomDataSet.elements = [
        { tag: 'x00080016', vr: 'UI', value: studyUID },
        { tag: 'x0020000d', vr: 'UI', value: studyUID },
        { tag: 'x0020000e', vr: 'UI', value: seriesUID },
        { tag: 'x00080018', vr: 'UI', value: objectUID },
        // ... 其他必要的数据元素
      ];

      // 将 DICOM 图像数据添加到 DataSet 中
      dicomDataSet.byteArray = dicomImageData;

      // 创建 DICOM C-STORE 请求对象
      const cStoreRequest = {
        operation: 'C-STORE',
        dataSet: dicomDataSet,
      };

      return cStoreRequest;
    }

    async function sendDicomImagesToPrintSequential(cStoreRequests) {
      try {
        // 串行发送所有请求
        for (let i = 0; i < cStoreRequests.length; i++) {
          const response = await sendCStoreRequest(cStoreRequests[i]);

          if (response.ok) {
            console.log(`Request ${i + 1} succeeded`);
          } else {
            console.error(`Request ${i + 1} failed: ${response.statusText}`);
          }
        }
      } catch (error) {
        console.error('Error sending DICOM images to print:', error);
      }
    }

    async function sendCStoreRequest(cStoreRequest) {
      let printServiceEndpoint = 'https://10.10.31.196:13001/dicomweb/';
      try {
        const response = await fetch(printServiceEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/dicom',
            // 添加其他可能需要的头部信息
          },
          body: JSON.stringify(cStoreRequest), // 将请求体转换为 JSON 字符串
        });

        return response;
      } catch (error) {
        console.error('Error sending C-STORE request:', error);
        throw error; // 重新抛出错误，以便上层代码能够处理
      }
    }

    function selectAll(flag) {}

    let defaultChecked = true;

    const openModal = () => {
      Modal.confirm({
        title: '胶片打印',
        content: (
          <div style={{ marginTop: '20px', height: '65vh' }}>
            <p
              style={{
                marginTop: '20px',
                marginBottom: '20px',
                fontSize: '15px',
                fontWeight: '600',
              }}
            >
              选择需要胶片打印的图像
            </p>
            <div
              style={{
                maxHeight: '60vh',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  selectAll(true);
                }}
              >
                全选
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  selectAll(false);
                }}
              >
                全不选
              </Button>
              <Row gutter={16} style={{ marginTop: 16 }}>
                {selectedImages.map((image, index) => (
                  <Col key={index} span={4}>
                    <Checkbox
                      defaultChecked={defaultChecked}
                      onChange={() => handleImageSelect(image, index)}
                    >
                      <img
                        alt={'img' + index}
                        src={image.url}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        ),
        closable: true,
        okText: '确定打印', // 更改确定按钮的文本
        cancelText: '取消打印', // 更改确定按钮的文本
        icon: null, // 禁用默认图标
        width: '50%', // 自定义宽度
        okButtonProps: {
          className: 'custom-ok-button', // 自定义类名
          style: { background: 'blue', borderColor: 'blue' }, // 自定义样式
        },
        onOk() {
          let printList = [];
          // get actor from the viewport
          for (let i = 0; i < selectedImages.length; i++) {
            if (selectedImages[i].checked) {
              const imageUrl =
                selectedImages[i].image.imageId.split('dicomweb')[1];
              const parts = imageUrl.split('/');
              const studyUID = parts[2];
              const seriesUID = parts[4];
              const objectUID = parts[6];
              const cStoreRequest = buildCStoreRequest(
                studyUID,
                seriesUID,
                objectUID,
                selectedImages[i].image.getPixelData()
              );
              printList.push(cStoreRequest);
            }
          }
          if (printList.length == 0) {
            uiNotificationService.show({
              title: '打印警告',
              message: '请至少选择一张图像进行打印',
              type: 'error',
              duration: 2000, // 通知持续2秒
            });
            return;
          } else {
            uiNotificationService.show({
              title: '打印中',
              message: '正在对打印机进行网络请求，请稍后...',
              type: 'info',
              duration: 3000, // 通知持续2秒
            });
            sendDicomImagesToPrintSequential(printList);
            setTimeout(() => {
              uiNotificationService.show({
                title: '错误',
                message: '网络连接打印机失败，请重新链接后再试。',
                type: 'error',
                duration: 5000, // 通知持续2秒
              });
            }, 5 * 1000);
          }
        },
      });
    };
    openModal();
  };

  function _getActiveViewportEnabledElement() {
    return getActiveViewportEnabledElement(viewportGridService);
  }

  // 定义锐化滤波函数
  function sharpen(imageData) {
    const width = imageData.width;
    const height = imageData.height;
    const data = imageData.data;
    const newData = new Uint8ClampedArray(data.length);
    // 定义锐化矩阵
    const matrix = [
      [-1, -1, -1],
      [-1, 9, -1],
      [-1, -1, -1],
    ];

    // 遍历图像中的每个像素
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        // 计算当前像素在数据数组中的索引
        let index = (y * width + x) * 4;

        // 对当前像素进行锐化滤波
        let r = 0,
          g = 0,
          b = 0;
        for (let i = 0; i < 3; i++) {
          for (let j = 0; j < 3; j++) {
            let x1 = x + j - 1;
            let y1 = y + i - 1;
            if (x1 >= 0 && x1 < width && y1 >= 0 && y1 < height) {
              let index1 = (y1 * width + x1) * 4;
              r += data[index1] * matrix[i][j];
              g += data[index1 + 1] * matrix[i][j];
              b += data[index1 + 2] * matrix[i][j];
            }
          }
        }
        newData[index] = r;
        newData[index + 1] = g;
        newData[index + 2] = b;
        newData[index + 3] = data[index + 3];
      }
    }

    // 将处理后的数据复制回原数据数组
    for (var i = 0; i < data.length; i++) {
      data[i] = newData[i];
    }

    return imageData;
  }

  const freeRotation = e => {
    const enabledElement = _getActiveViewportEnabledElement();
    const renderingEngine = cornerstoneViewportService.getRenderingEngine();
    const viewport = renderingEngine.getViewport(enabledElement.viewportId);
    toolbarService.setDragging(e);
    // 获取图像数据
    const element = viewport.element;
    let isDragging = false;
    let startX = 0;
    let startY = 0;
    let currentX = 0;
    let currentY = 0;
    let angle = 0;

    element.addEventListener('mousedown', event => {
      isDragging = true;
      startX = event.clientX;
      startY = event.clientY;
    });

    element.addEventListener('mousemove', event => {
      const Dragging = toolbarService.getDragging();
      if (isDragging && Dragging) {
        currentX = event.clientX;
        currentY = event.clientY;
        const deltaX = currentX - startX;
        const deltaY = currentY - startY;
        angle = (Math.atan2(deltaY, deltaX) * 180) / Math.PI;
        viewport.setProperties({ rotation: angle });
        viewport.render();
      }
    });

    element.addEventListener('mouseup', () => {
      isDragging = false;
    });
    // element.addEventListener('wheel', (event) => {
    //   event.preventDefault();
    //   // 获取鼠标滚轮的滚动量
    //   const delta = event.deltaY;
    //   // 根据滚动量计算旋转角度
    //   angle += delta * 0.1;
    //   // 将旋转角度应用到图片或模型上
    //   // element.style.transform = `rotate(${angle}deg)`;
    //   viewport.setProperties({ rotation: angle });
    //   viewport.render();
    // });
  };

  const drawActions = e => {
    const enabledElement = _getActiveViewportEnabledElement();
    const renderingEngine = cornerstoneViewportService.getRenderingEngine();
    const viewport = renderingEngine.getViewport(enabledElement.viewportId);
    toolbarService.setDraw(e);
    // 获取图像数据
    const canvas = viewport.canvas;
    // 获取图像数据
    const ctx = canvas.getContext('2d');
    ctx.strokeStyle = 'rgb(0, 220, 0)';
    ctx.lineWidth = 1;
    let isDrawing = false;
    let lastX, lastY;

    canvas.addEventListener('mousedown', function (event) {
      isDrawing = true;
      ctx.strokeStyle = 'rgb(0, 220, 0)';
      ctx.lineWidth = 1;
      const rect = canvas.getBoundingClientRect();
      lastX = event.clientX - rect.left;
      lastY = event.clientY - rect.top;
    });
    canvas.addEventListener('mousemove', function (event) {
      const Drawing = toolbarService.getDraw();
      if (isDrawing && Drawing) {
        // const rect = canvas.getBoundingClientRect();
        // let currentX = event.clientX - rect.left;
        // let currentY = event.clientY - rect.top;
        // ctx.beginPath();
        // ctx.moveTo(lastX, lastY);
        // ctx.lineTo(currentX, currentY);
        // ctx.stroke();
        // lastX = currentX;
        // lastY = currentY;
        //
      } else {
        isDrawing = false;
      }
    });
    canvas.addEventListener('mouseup', function (event) {
      const imageDataURL = canvas.toDataURL('image/png');
      isDrawing = false;
    });
    const measurements = measurementService.getMeasurements();
    console.log('measurements', measurements);
  };

  const actions = {
    /**
     * Generates the selector props for the context menu, specific to
     * the cornerstone viewport, and then runs the context menu.
     */
    showCornerstoneContextMenu: options => {
      const element = _getActiveViewportEnabledElement()?.viewport?.element;

      const optionsToUse = { ...options, element };
      const { useSelectedAnnotation, nearbyToolData, event } = optionsToUse;

      // This code is used to invoke the context menu via keyboard shortcuts
      if (useSelectedAnnotation && !nearbyToolData) {
        const firstAnnotationSelected = getFirstAnnotationSelected(element);
        // filter by allowed selected tools from config property (if there is any)
        const isToolAllowed =
          !optionsToUse.allowedSelectedTools ||
          optionsToUse.allowedSelectedTools.includes(
            firstAnnotationSelected?.metadata?.toolName
          );
        if (isToolAllowed) {
          optionsToUse.nearbyToolData = firstAnnotationSelected;
        } else {
          return;
        }
      }

      optionsToUse.defaultPointsPosition = [];
      // if (optionsToUse.nearbyToolData) {
      //   optionsToUse.defaultPointsPosition = commandsManager.runCommand(
      //     'getToolDataActiveCanvasPoints',
      //     { toolData: optionsToUse.nearbyToolData }
      //   );
      // }

      // TODO - make the selectorProps richer by including the study metadata and display set.
      optionsToUse.selectorProps = {
        toolName: optionsToUse.nearbyToolData?.metadata?.toolName,
        value: optionsToUse.nearbyToolData,
        uid: optionsToUse.nearbyToolData?.annotationUID,
        nearbyToolData: optionsToUse.nearbyToolData,
        event,
        ...optionsToUse.selectorProps,
      };

      commandsManager.run(options, optionsToUse);
    },

    getNearbyToolData({ nearbyToolData, element, canvasCoordinates }) {
      return (
        nearbyToolData ??
        cstUtils.getAnnotationNearPoint(element, canvasCoordinates)
      );
    },
    getNearbyAnnotation({ element, canvasCoordinates }) {
      const nearbyToolData = actions.getNearbyToolData({
        nearbyToolData: null,
        element,
        canvasCoordinates,
      });

      const isAnnotation = toolName => {
        const enabledElement = getEnabledElement(element);

        if (!enabledElement) {
          return;
        }

        const { renderingEngineId, viewportId } = enabledElement;
        const toolGroup = ToolGroupManager.getToolGroupForViewport(
          viewportId,
          renderingEngineId
        );

        const toolInstance = toolGroup.getToolInstance(toolName);

        return toolInstance?.constructor?.isAnnotation ?? true;
      };

      return nearbyToolData?.metadata?.toolName &&
        isAnnotation(nearbyToolData.metadata.toolName)
        ? nearbyToolData
        : null;
    },

    // Measurement tool commands:

    /** Delete the given measurement */
    deleteMeasurement: ({ uid }) => {
      if (uid) {
        measurementServiceSource.remove(uid);
      }
    },

    /**
     * Show the measurement labelling input dialog and update the label
     * on the measurement with a response if not cancelled.
     */
    setMeasurementLabel: ({ uid }) => {
      const measurement = measurementService.getMeasurement(uid);

      callInputDialog(
        uiDialogService,
        measurement,
        (label, actionId) => {
          if (actionId === 'cancel') {
            return;
          }

          const updatedMeasurement = Object.assign({}, measurement, {
            label,
          });

          measurementService.update(
            updatedMeasurement.uid,
            updatedMeasurement,
            true
          );
        },
        false
      );
    },

    /**
     *
     * @param props - containing the updates to apply
     * @param props.measurementKey - chooses the measurement key to apply the
     *        code to.  This will typically be finding or site to apply a
     *        finind code or a findingSites code.
     * @param props.code - A coding scheme value from DICOM, including:
     *       * CodeValue - the language independent code, for example '1234'
     *       * CodingSchemeDesignator - the issue of the code value
     *       * CodeMeaning - the text value shown to the user
     *       * ref - a string reference in the form `<designator>:<codeValue>`
     *       * Other fields
     *     Note it is a valid option to remove the finding or site values by
     *     supplying null for the code.
     * @param props.uid - the measurement UID to find it with
     * @param props.label - the text value for the code.  Has NOTHING to do with
     *        the measurement label, which can be set with textLabel
     * @param props.textLabel is the measurement label to apply.  Set to null to
     *            delete.
     *
     * If the measurementKey is `site`, then the code will also be added/replace
     * the 0 element of findingSites.  This behaviour is expected to be enhanced
     * in the future with ability to set other site information.
     */
    updateMeasurement: props => {
      const { code, uid, textLabel, label } = props;
      const measurement = measurementService.getMeasurement(uid);
      const updatedMeasurement = {
        ...measurement,
      };
      // Call it textLabel as the label value
      // TODO - remove the label setting when direct rendering of findingSites is enabled
      if (textLabel !== undefined) {
        updatedMeasurement.label = textLabel;
      }
      if (code !== undefined) {
        const measurementKey = code.type || 'finding';

        if (code.ref && !code.CodeValue) {
          const split = code.ref.indexOf(':');
          code.CodeValue = code.ref.substring(split + 1);
          code.CodeMeaning = code.text || label;
          code.CodingSchemeDesignator = code.ref.substring(0, split);
        }
        updatedMeasurement[measurementKey] = code;
        // TODO - remove this line once the measurements table customizations are in
        if (measurementKey !== 'finding') {
          if (updatedMeasurement.findingSites) {
            updatedMeasurement.findingSites =
              updatedMeasurement.findingSites.filter(
                it => it.type !== measurementKey
              );
            updatedMeasurement.findingSites.push(code);
          } else {
            updatedMeasurement.findingSites = [code];
          }
        }
      }
      measurementService.update(
        updatedMeasurement.uid,
        updatedMeasurement,
        true
      );
    },

    // Retrieve value commands
    getActiveViewportEnabledElement: _getActiveViewportEnabledElement,

    setViewportActive: ({ viewportId }) => {
      const viewportInfo =
        cornerstoneViewportService.getViewportInfo(viewportId);
      if (!viewportInfo) {
        console.warn('No viewport found for viewportId:', viewportId);
        return;
      }

      const viewportIndex = viewportInfo.getViewportIndex();
      console.log('dee');
      viewportGridService.setActiveViewportIndex(viewportIndex);
    },
    arrowTextCallback: ({ callback, data }) => {
      callInputDialog(uiDialogService, data, callback);
    },
    toggleCine: () => {
      const { viewports } = viewportGridService.getState();
      const { isCineEnabled } = cineService.getState();
      cineService.setIsCineEnabled(!isCineEnabled);
      toolbarService.setButton('Cine', { props: { isActive: !isCineEnabled } });
      viewports.forEach((_, index) =>
        cineService.setCine({ id: index, isPlaying: false })
      );
    },
    set3DVolume: e => {
      const enabledElement = _getActiveViewportEnabledElement();
      const renderingEngine = cornerstoneViewportService.getRenderingEngine();
      const { viewports } = viewportGridService.getState();
      const viewport = renderingEngine.getViewport(viewports[1].viewportId);
      const volumeActor = viewport.getDefaultActor().actor as Types.VolumeActor;

      csUtils.applyPreset(
        volumeActor,
        CONSTANTS.VIEWPORT_PRESETS.find(preset => preset.name === e.toolName)
      );
      viewport.render();
    },
    setMPRChange: e => {
      const enabledElement = _getActiveViewportEnabledElement();
      const renderingEngine = cornerstoneViewportService.getRenderingEngine();
      const { viewports } = viewportGridService.getState();
      let viewport1 = renderingEngine.getViewport(viewports[0].viewportId);
      let viewport2 = renderingEngine.getViewport(viewports[1].viewportId);
      let viewport3 = renderingEngine.getViewport(viewports[2].viewportId);
      let list = [1, 2, 3];
      const orientationOptions = {
        axial: 'axial',
        sagittal: 'sagittal',
        coronal: 'coronal',
      };
      // // console.log([viewports[0], viewports[1], viewports[2]]);
      const clickedToolName = e.toolName; // 点击的工具名称
      viewports.map((v, index) => {
        const viewport = renderingEngine.getViewport(v.viewportId);
        if ('axial' == v.viewportOptions.orientation) {
          list[0] = v;
          viewport1 = renderingEngine.getViewport(v.viewportId);
        }
        if ('sagittal' == v.viewportOptions.orientation) {
          list[1] = v;
          viewport2 = renderingEngine.getViewport(v.viewportId);
        }
        if ('coronal' == v.viewportOptions.orientation) {
          list[2] = v;
          viewport3 = renderingEngine.getViewport(v.viewportId);
        }
        // if (index == e.index) {
        //   viewport.setOrientation(v.viewportOptions.orientation);
        // }
        // viewport.render();
      });
      //AAAAAAAAAAAAAAAAAAAAAAAAA
      if (clickedToolName === orientationOptions.axial && e.index == 0) {
        viewport2.setOrientation(EnumsType.OrientationAxis.AXIAL);
        viewport1.setOrientation(EnumsType.OrientationAxis.SAGITTAL);
        viewport3.setOrientation(EnumsType.OrientationAxis.CORONAL);
        viewportGridService.set({
          viewports: [list[0], list[1], list[2]],
        });

        // viewport2.render();
        // viewport1.render();
        // viewport3.render();
      }

      if (clickedToolName === orientationOptions.sagittal && e.index == 0) {
        viewport2.setOrientation(EnumsType.OrientationAxis.AXIAL);
        viewport1.setOrientation(EnumsType.OrientationAxis.SAGITTAL);
        viewport3.setOrientation(EnumsType.OrientationAxis.CORONAL);
        viewportGridService.set({
          viewports: [list[1], list[0], list[2]],
        });
        // setEnabledElement(1, ele[0]);
        // viewport2.render();
        // viewport1.render();
        // viewport3.render();
      }

      if (clickedToolName === orientationOptions.coronal && e.index == 0) {
        viewport3.setOrientation(EnumsType.OrientationAxis.AXIAL);
        viewport2.setOrientation(EnumsType.OrientationAxis.SAGITTAL);
        viewport1.setOrientation(EnumsType.OrientationAxis.CORONAL);
        viewportGridService.set({
          viewports: [list[2], list[1], list[0]],
        });
        // viewport3.render();
        // viewport2.render();
        // viewport1.render();
      }
      //BBBBBBBBBBBBBBBBB
      if (clickedToolName === orientationOptions.axial && e.index == 1) {
        viewport2.setOrientation(EnumsType.OrientationAxis.AXIAL);
        viewport1.setOrientation(EnumsType.OrientationAxis.SAGITTAL);
        viewport3.setOrientation(EnumsType.OrientationAxis.CORONAL);
        // viewport2.render();
        // viewport1.render();
        // viewport3.render();
        viewportGridService.set({
          viewports: [list[1], list[0], list[2]],
        });
      }

      if (clickedToolName === orientationOptions.sagittal && e.index == 1) {
        viewport1.setOrientation(EnumsType.OrientationAxis.AXIAL);
        viewport2.setOrientation(EnumsType.OrientationAxis.SAGITTAL);
        viewport3.setOrientation(EnumsType.OrientationAxis.CORONAL);
        // viewport1.render();
        // viewport2.render();
        // viewport3.render();
        viewportGridService.set({
          viewports: [list[0], list[1], list[2]],
        });
      }

      if (clickedToolName === orientationOptions.coronal && e.index == 1) {
        viewport1.setOrientation(EnumsType.OrientationAxis.AXIAL);
        viewport3.setOrientation(EnumsType.OrientationAxis.SAGITTAL);
        viewport2.setOrientation(EnumsType.OrientationAxis.CORONAL);

        // viewport1.render();
        // viewport3.render();
        // viewport2.render();
        viewportGridService.set({
          viewports: [viewports[0], viewports[2], viewports[1]],
        });
      }
      //CCCCCCCCCCCCC
      if (clickedToolName === orientationOptions.axial && e.index == 2) {
        viewport3.setOrientation(EnumsType.OrientationAxis.AXIAL);
        viewport2.setOrientation(EnumsType.OrientationAxis.SAGITTAL);
        viewport1.setOrientation(EnumsType.OrientationAxis.CORONAL);
        // viewport3.render();
        // viewport2.render();
        // viewport1.render();
        viewportGridService.set({
          viewports: [list[2], list[1], list[0]],
        });
      }

      if (clickedToolName === orientationOptions.sagittal && e.index == 2) {
        viewport1.setOrientation(EnumsType.OrientationAxis.AXIAL);
        viewport3.setOrientation(EnumsType.OrientationAxis.SAGITTAL);
        viewport2.setOrientation(EnumsType.OrientationAxis.CORONAL);
        // viewport1.render();
        // viewport3.render();
        // viewport2.render();
        viewportGridService.set({
          viewports: [list[0], list[2], list[1]],
        });
      }

      if (clickedToolName === orientationOptions.coronal && e.index == 2) {
        viewport1.setOrientation(EnumsType.OrientationAxis.AXIAL);
        viewport2.setOrientation(EnumsType.OrientationAxis.SAGITTAL);
        viewport3.setOrientation(EnumsType.OrientationAxis.CORONAL);

        // viewport1.render();
        // viewport2.render();
        // viewport3.render();
        viewportGridService.set({
          viewports: [list[0], list[1], list[2]],
        });
      }
      viewport1.resetProperties();
      viewport1.resetCamera();
      viewport2.resetProperties();
      viewport2.resetCamera();
      viewport3.resetProperties();
      viewport3.resetCamera();

      viewport1.render();
      viewport2.render();
      viewport3.render();

      // const keysIterator = enabledElement.viewport._actors.keys();
      // // @ts-ignore
      // for (const key of keysIterator) {
      //   const startIndex = key.indexOf(':'); // 找到冒号的位置
      //   if (startIndex !== -1) {
      //     const extractedKey = key.split(':')[1]; // 提取冒号前的部分
      //     console.log(extractedKey); // 这里就是 'cornerstoneStreamingImageVolume'
      //     displaySetService.setDisplaySetMetadataInvalidated(extractedKey);
      //   }
      // }
      // switch (e.toolName) {
      //   case orientationOptions.axial:
      //     viewport.setOrientation(EnumsType.OrientationAxis.AXIAL);

      //     break;
      //   case orientationOptions.sagittal:
      //     viewport.setOrientation(EnumsType.OrientationAxis.SAGITTAL);

      //     break;
      //   case orientationOptions.coronal:
      //     viewport.setOrientation(EnumsType.OrientationAxis.CORONAL);

      //     break;
      // }

      // viewport.render();
    },
    toggleFit: ({ toolName }) => {
      const renderingEngine = cornerstoneViewportService.getRenderingEngine();
      const { viewports } = viewportGridService.getState();
      console.log(viewports);
      if (toolName == 'FitWindow') {
        window.config.fitWindow = true;
        viewports.forEach(v => {
          const view = renderingEngine.getViewport(v.viewportId);
          if (view) {
            view.setZoom(1);
            view.render();
          }
        });
      }
      if (toolName == 'FitImage') {
        window.config.fitWindow = false;
        viewports.forEach((v, index) => {
          let offsetHeight = 700;
          let offsetwidth = 1000;
          const canvases =
            document.getElementsByClassName('cornerstone-canvas');
          if (canvases[index]) {
            offsetHeight = canvases[index].offsetHeight;
            offsetwidth = canvases[index].offsetWidth;
          }
          const displaySet = displaySetService.getDisplaySetByUID(
            v.displaySetInstanceUIDs[0]
          );
          let rows = displaySet.images[0].Rows;
          let columns = displaySet.images[0].Columns;
          const view = renderingEngine.getViewport(v.viewportId);
          view.setZoom(
            rows / offsetwidth > columns / offsetHeight
              ? (rows / offsetwidth).toFixed(4)
              : (columns / offsetHeight).toFixed(4)
          );
          view.render();
        });
      }
    },
    sharpening: () => {
      const enabledElement = _getActiveViewportEnabledElement();
      const renderingEngine = cornerstoneViewportService.getRenderingEngine();
      const viewport = renderingEngine.getViewport(enabledElement.viewportId);

      // 获取图像数据
      const { canvas } = enabledElement.viewport;
      const ctx = canvas.getContext('2d');
      let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      // 对图像进行锐化滤波
      imageData = sharpen(imageData);
      // 将处理后的图像数据绘制到画布上
      ctx.putImageData(imageData, 0, 0);
    },
    handleScaleClick: ({ toolName }) => {
      const enabledElement = _getActiveViewportEnabledElement();
      const renderingEngine = cornerstoneViewportService.getRenderingEngine();
      const viewport = renderingEngine.getViewport(enabledElement.viewportId);
      //       NEAREST: 最近邻插值。它选择最接近目标像素的已知像素值作为估计值。它是一种不进行颜色平滑处理的快速插值方法。
      // LINEAR: 线性插值。该插值方法通过对两个最近的已知像素值进行加权平均来估计目标像素的值。它会考虑周围像素的权重，以实现平滑的过渡。
      // FAST_LINEAR: 快速线性插值。它是对线性插值的一种优化，可能在某些情况下提供更快的计算速度，但结果可能略有不同。
      viewport.setProperties({
        interpolationType:
          toolName == 'LINEAR'
            ? EnumsType.InterpolationType.LINEAR
            : EnumsType.InterpolationType.NEAREST,
      });
      viewport.render();
    },
    ExitFusionIamge: () => {},
    radiographicPrinting: () => {
      radiographicPrintingModal(true);
    },
    setOrderFeature({ description, level, toolGroupId }) {
      if (description == '1') {
        window.config.FitWindow = true;
        window.config.FitImage = false;
        toolbarService.setRadioButton('FitWindow');
        toolbarService.recordInteraction({
          groupId: 'FitWindow',
          itemId: 'FitWindow',
          interactionType: 'action',
          commands: [
            {
              commandName: 'toggleFit',
              commandOptions: {
                toolName: 'FitWindow',
              },
              context: 'CORNERSTONE',
            },
          ],
        });
      }
      if (description == '2') {
        window.config.FitImage = true;
        window.config.FitWindow = false;
        toolbarService.setRadioButton('FitImage');
        toolbarService.recordInteraction({
          groupId: 'FitImage',
          itemId: 'FitImage',
          interactionType: 'action',
          commands: [
            {
              commandName: 'toggleFit',
              commandOptions: {
                toolName: 'FitImage',
              },
              context: 'CORNERSTONE',
            },
          ],
        });
      }
      if (description == '3') {
        toolbarService.setIndexSyn(false);
        toolbarService.setPositionSyn(true);
        window.config.IndexSyn = false;
        window.config.PositionSyn = true;
      }
      if (description == '4') {
        toolbarService.setPositionSyn(false);
        toolbarService.setIndexSyn(true);
        window.config.IndexSyn = true;
        window.config.PositionSyn = false;
      }
      if (description == '5') {
        window.windowLevel = !window.windowLevel;
      }
      if (description == '6') {
        window.markingFlag = !window.markingFlag;
      }
      if (description == '7') {
        let url = window.location.origin + `/local`;
        window.open(url);
      }
      if (description == '8') {
        openImgModal();
      }
      if (description == '9') {
        openVideoModal();
      }
      if (description == '10') {
        window.config.StackScroll = true;
        window.config.FreeRotation = false;
        window.config.Magnify = false;
        const args = {
          itemId: 'StackScroll',
          interactionType: 'tool',
          commands: [
            {
              commandName: 'setToolActive',
              commandOptions: { toolName: 'StackScroll' },
              context: 'CORNERSTONE',
            },
          ],
        };
        toolbarService.recordInteraction(args);
      }
      if (description == '11') {
        if (
          window.location.href.indexOf('tmtv?') > -1 ||
          window.location.href.indexOf(
            '&hangingprotocolId=mprAnd3DVolumeViewport'
          ) > -1
        ) {
          window.config.ReferenceLines = true;
          const args = {
            itemId: 'ReferenceLines',
            interactionType: 'toggle',
            commands: [
              {
                commandName: 'toggleReferenceLines',
                commandOptions: {},
                context: 'CORNERSTONE',
              },
            ],
          };
          toolbarService.recordInteraction(args);
        } else {
          uiNotificationService.show({
            title: '切换失败',
            message: '参考线仅能在3D模式和融合模式下使用。',
            type: 'error',
            duration: 4000,
          });
          return;
        }
      }
      if (description == '12') {
        window.config.StackScroll = false;
        window.config.FreeRotation = true;
        window.config.Magnify = false;
        const args = {
          itemId: 'FreeRotation',
          interactionType: 'tool',
          commands: [
            {
              commandName: 'setToolActive',
              commandOptions: { toolName: 'FreeRotation' },
              context: 'CORNERSTONE',
            },
          ],
        };
        toolbarService.recordInteraction(args);
      }
      if (description == '13') {
        window.config.StackScroll = false;
        window.config.FreeRotation = false;
        window.config.Magnify = false;
        const args = {
          itemId: 'rotate-right',
          interactionType: 'action',
          commands: [
            {
              commandName: 'rotateViewportCW',
              commandOptions: {},
              context: 'CORNERSTONE',
            },
          ],
        };
        toolbarService.recordInteraction(args);
      }
      if (description == '14') {
        window.config.StackScroll = false;
        window.config.FreeRotation = false;
        window.config.Magnify = false;
        const args = {
          itemId: 'flip-horizontal',
          interactionType: 'action',
          commands: [
            {
              commandName: 'flipViewportHorizontal',
              commandOptions: {},
              context: 'CORNERSTONE',
            },
          ],
        };
        toolbarService.recordInteraction(args);
      }
      if (description == '15') {
        window.config.StackScroll = false;
        window.config.FreeRotation = false;
        window.config.Magnify = false;
        const args = {
          itemId: 'invert',
          interactionType: 'action',
          commands: [
            {
              commandName: 'invertViewport',
              commandOptions: {},
              context: 'CORNERSTONE',
            },
          ],
        };
        toolbarService.recordInteraction(args);
      }
      if (description == '16') {
        window.config.StackScroll = false;
        window.config.FreeRotation = false;
        window.config.Magnify = true;
        const args = {
          itemId: 'Magnify',
          interactionType: 'tool',
          commands: [
            {
              commandName: 'setToolActive',
              commandOptions: { toolName: 'Magnify' },
              context: 'CORNERSTONE',
            },
          ],
        };
        toolbarService.recordInteraction(args);
      }
      if (description == '17') {
        // const args = {
        //   itemId:null,
        //   interactionType:null,
        //   commands:[{commandName: 'toggleReferenceLines', commandOptions: {}, context: 'CORNERSTONE'}]
        // }
        // toolbarService.recordInteraction(args);
      }
    },
    setUploadAndDownload({ description, level, toolGroupId }) {
      if (description == '1') {
        let url = window.location.origin + `/local`;
        window.open(url);
      }
      if (description == '2') {
        openImgModal();
      }
      if (description == '3') {
        openVideoModal();
      }
    },
    setColorOverlay({ window, level, toolGroupId }) {
      const { viewportId } = _getActiveViewportEnabledElement();
      const viewportToolGroupId =
        toolGroupService.getToolGroupForViewport(viewportId);

      if (toolGroupId && toolGroupId !== viewportToolGroupId) {
        return;
      }

      // get actor from the viewport
      const renderingEngine = cornerstoneViewportService.getRenderingEngine();
      const viewport = renderingEngine.getViewport(viewportId);

      let Colormap = {
        Name: window, // 伪彩色的名字
        RGBPoints: level,
        ColorSpace: 'RGB',
      };

      // 现在 canvases 数组包含了该序列中所有图像的 canvas

      if (window == '清除颜色') {
        viewport.setColormap(Colormap);
        let upper = 165;
        let lower = -15;
        viewport.setProperties({
          voiRange: { upper, lower },
        });
        viewport.render();
        upper = 240;
        lower = -160;
        viewport.setProperties({
          voiRange: { upper, lower },
        });
      } else {
        viewport.setColormap(Colormap);
      }

      const windowWidthNum = Number(1314);
      const windowCenterNum = Number(520);

      const { lower, upper } = csUtils.windowLevel.toLowHighRange(
        windowWidthNum,
        windowCenterNum
      );
      viewport.setProperties({
        voiRange: {
          upper,
          lower,
        },
      });

      viewport.resetProperties();
      viewport.resetCamera();
      if (viewport.modality == 'DX') {
        const { invert } = viewport.getProperties();
        if (invert) {
          viewport.setProperties({ invert: !invert });
        }
      }
      viewport.render();

      // Convert image to false color
      // const currentImage = cornerstone.getImage(viewport.element);
      // const falseColorImage = cornerstoneWADOImageLoader.convertToFalseColorImage(currentImage);
      // if (window == '清除颜色') {
      //   falseColorImage.viewport.setColormap(Colormap);
      //   let upper = 165;
      //   let lower = -15;
      //   falseColorImage.viewport.setProperties({
      //     voiRange: { upper, lower },
      //   });
      //   falseColorImage.viewport.render();
      //   upper = 240;
      //   lower = -160;
      //   falseColorImage.viewport.setProperties({
      //     voiRange: { upper, lower },
      //   });
      // } else {
      //   falseColorImage.viewport.setColormap(Colormap);
      // }
      //
      // falseColorImage.viewport.render();
    },

    setWindowLevel({ windowH, level, toolGroupId }) {
      if (windowH == '1' && level == '1') {
        return;
      }
      // convert to numbers
      const windowWidthNum = Number(windowH);
      const windowCenterNum = Number(level);

      const { viewportId } = _getActiveViewportEnabledElement();
      const viewportToolGroupId =
        toolGroupService.getToolGroupForViewport(viewportId);

      if (toolGroupId && toolGroupId !== viewportToolGroupId) {
        return;
      }

      // get actor from the viewport
      const renderingEngine = cornerstoneViewportService.getRenderingEngine();
      const viewport = renderingEngine.getViewport(viewportId);

      const { lower, upper } = csUtils.windowLevel.toLowHighRange(
        windowWidthNum,
        windowCenterNum
      );
      viewport.setProperties({
        voiRange: {
          upper,
          lower,
        },
      });
      console.log(window.windowLevel);
      console.log(window.windowLevelData);
      window.windowLevelData = { upper: upper, lower: lower };
      viewport.render();
    },
    // Just call the toolbar service record interaction - allows
    // executing a toolbar command as a full toolbar command with side affects
    // coming from the ToolbarService itself.
    toolbarServiceRecordInteraction: props => {
      toolbarService.recordInteraction(props);
    },

    setToolActive: ({ toolName, toolGroupId = null }) => {
      console.log('dee');
      if (toolName == 'ExitButton') {
        const enabledElement = _getActiveViewportEnabledElement();
        const renderingEngine = cornerstoneViewportService.getRenderingEngine();
        const viewport = renderingEngine.getViewport(enabledElement.viewportId);
        viewport.resetProperties();
      }
      const Element = _getActiveViewportEnabledElement();
      if (Element.viewport.type == 'volume3d') {
        uiNotificationService.show({
          title: '错误',
          message: '请勿在选中3D模型时切换常用工具或试图编辑3D模型。',
          type: 'error',
          duration: 5000,
        });
        const toolGroup = toolGroupService.getToolGroupForViewport(
          Element.viewportId
        );
        toolGroup.toolOptions[toolName].bindings = [];
        toolGroup.toolOptions[toolName].mode = 'Passive';
        let ExitButtonArgs = {
          interactionType: 'tool',
          itemId: 'ExitButton',
          commands: [
            {
              commandName: 'setToolActive',
              commandOptions: { toolName: 'ExitButton' },
              context: 'CORNERSTONE',
              toggledState: true,
            },
          ],
        };
        toolbarService.recordInteraction(ExitButtonArgs);
        return;
      }
      if (toolName === 'StackScroll') {
        const toolGroup = toolGroupService.getToolGroupForViewport(
          Element.viewportId
        );
        if (toolGroup.toolOptions[toolName].mode == 'Active') {
          toolGroup.toolOptions[toolName].bindings = [];
          toolGroup.toolOptions[toolName].mode = 'Passive';
          setTimeout(() => {
            toolbarService.recordInteraction({
              groupId: 'WindowLevel',
              itemId: 'WindowLevel',
              interactionType: 'tool',
              commands: [
                {
                  commandName: 'setToolActive',
                  commandOptions: {
                    toolName: 'WindowLevel',
                  },
                  context: 'CORNERSTONE',
                },
              ],
            });
          }, 200);
          return;
        }
      }
      if (toolName === 'Pan') {
      }
      if (toolName === 'PlanarFreehandROI') {
        const { viewportId } = _getActiveViewportEnabledElement();
        const toolGroup = toolGroupService.getToolGroupForViewport(viewportId);
        // set interpolation to be ON while editing only
        toolGroup.setToolConfiguration(toolName, {
          interpolation: {
            interpolateOnAdd: true,
            interpolateOnEdit: true,
            isDrawing: true,
            isEditingClosed: true,
            isEditingOpen: true,
          },
        });
      }
      if (toolName === 'Crosshairs') {
        const activeViewportToolGroup = toolGroupService.getToolGroup(null);

        if (
          !activeViewportToolGroup._toolInstances.Crosshairs &&
          activeViewportToolGroup.id != 'ptctFusion' &&
          window.location.href.indexOf(
            'hangingprotocolId=mprAnd3DVolumeViewport'
          ) < 0
        ) {
          uiNotificationService.show({
            title: '十字准线',
            message:
              '您需要在MPR视图中才能使用“十字线”。单击工具栏中的MPR按钮将其激活。',
            type: 'info',
            duration: 3000,
          });

          throw new Error('Crosshairs tool is not available in this viewport');
        }
      }
      const { viewports } = viewportGridService.getState() || {
        viewports: [],
      };

      const toolGroup = toolGroupService.getToolGroup(toolGroupId);
      const toolGroupViewportIds = toolGroup?.getViewportIds?.();

      // if toolGroup has been destroyed, or its viewports have been removed
      if (!toolGroupViewportIds || !toolGroupViewportIds.length) {
        return;
      }

      const filteredViewports = viewports.filter(viewport => {
        if (!viewport.viewportOptions) {
          return false;
        }

        return toolGroupViewportIds.includes(
          viewport.viewportOptions.viewportId
        );
      });

      if (!filteredViewports.length) {
        return;
      }

      // if (!toolGroup.getToolInstance(toolName)) {
      //   uiNotificationService.show({
      //     title: `${toolName} tool`,
      //     message: `The ${toolName} tool is not available in this viewport.`,
      //     type: 'info',
      //     duration: 3000,
      //   });

      //   throw new Error(`ToolGroup ${toolGroup.id} does not have this tool.`);
      // }

      const activeToolName = toolGroup.getActivePrimaryMouseButtonTool();

      if (activeToolName) {
        // after another tool is selected. We should find a better way to do this
        if (activeToolName === 'Crosshairs') {
          toolGroup.setToolDisabled(activeToolName);
        } else {
          toolGroup.setToolPassive(activeToolName);
        }
      }
      freeRotation(toolName === 'FreeRotation');
      // drawActions(toolName === 'Freehandline');
      toolName = toolName === 'LengthCTR' ? 'Length' : toolName;
      // Set the new toolName to be active
      toolGroup.setToolActive(toolName, {
        bindings: [
          {
            mouseButton: Enums.MouseBindings.Primary,
          },
        ],
      });
    },
    showDownloadViewportModal: () => {
      const { activeViewportIndex } = viewportGridService.getState();

      if (
        !cornerstoneViewportService.getCornerstoneViewportByIndex(
          activeViewportIndex
        )
      ) {
        // Cannot download a non-cornerstone viewport (image).
        uiNotificationService.show({
          title: '下载图像',
          message: '无法下载图像',
          type: 'error',
        });
        return;
      }

      const { uiModalService } = servicesManager.services;

      if (uiModalService) {
        uiModalService.show({
          content: CornerstoneViewportDownloadForm,
          title: '下载',
          contentProps: {
            activeViewportIndex,
            onClose: uiModalService.hide,
            cornerstoneViewportService,
          },
        });
      }
    },
    rotateViewport: ({ rotation }) => {
      const enabledElement = _getActiveViewportEnabledElement();
      if (!enabledElement) {
        return;
      }

      const { viewport } = enabledElement;

      if (viewport instanceof StackViewport) {
        const { rotation: currentRotation } = viewport.getProperties();
        const newRotation = (currentRotation + rotation) % 360;
        viewport.setProperties({ rotation: newRotation });
        viewport.render();
      }
    },
    flipViewportHorizontal: () => {
      const enabledElement = _getActiveViewportEnabledElement();

      if (!enabledElement) {
        return;
      }

      const { viewport } = enabledElement;

      if (viewport instanceof StackViewport) {
        const { flipHorizontal } = viewport.getCamera();
        viewport.setCamera({ flipHorizontal: !flipHorizontal });
        viewport.render();
      }
    },
    flipViewportVertical: () => {
      const enabledElement = _getActiveViewportEnabledElement();

      if (!enabledElement) {
        return;
      }

      const { viewport } = enabledElement;

      if (viewport instanceof StackViewport) {
        const { flipVertical } = viewport.getCamera();
        viewport.setCamera({ flipVertical: !flipVertical });
        viewport.render();
      }
    },
    invertViewport: ({ element }) => {
      let enabledElement;

      if (element === undefined) {
        enabledElement = _getActiveViewportEnabledElement();
      } else {
        enabledElement = element;
      }

      if (!enabledElement) {
        return;
      }

      const { viewport } = enabledElement;

      if (viewport instanceof StackViewport) {
        const { invert } = viewport.getProperties();
        viewport.setProperties({ invert: !invert });
        viewport.render();
      }
    },
    resetViewport: () => {
      const enabledElement = _getActiveViewportEnabledElement();

      if (!enabledElement) {
        return;
      }

      const { viewport } = enabledElement;
      debugger;
      if (viewport instanceof StackViewport) {
        viewport.resetProperties();
        viewport.resetCamera();
        removeAllAnnotations(); //清除所有批注
        // measurementService.setRemover(); //清除批注编号的问题
      } else {
        // Todo: add reset properties for volume viewport
        viewport.resetCamera();
        removeAllAnnotations(); //清除所有批注
        measurementService.setRemover(); //清除批注编号的问题
      }

      if (viewport.modality == 'DX') {
        debugger;
        const { invert } = viewport.getProperties();
        if (invert) {
          viewport.setProperties({ invert: !invert });
        }
      }

      viewport.render();
    },
    scaleViewport: ({ direction }) => {
      const enabledElement = _getActiveViewportEnabledElement();
      const scaleFactor = direction > 0 ? 0.9 : 1.1;

      if (!enabledElement) {
        return;
      }
      const { viewport } = enabledElement;

      if (viewport instanceof StackViewport) {
        if (direction) {
          const { parallelScale } = viewport.getCamera();
          viewport.setCamera({ parallelScale: parallelScale * scaleFactor });
          viewport.render();
        } else {
          viewport.resetCamera();
          viewport.render();
        }
      }
    },

    /** Jumps the active viewport or the specified one to the given slice index */
    jumpToImage: ({ imageIndex, viewport: gridViewport }): void => {
      // Get current active viewport (return if none active)
      let viewport;
      if (!gridViewport) {
        const enabledElement = _getActiveViewportEnabledElement();
        if (!enabledElement) {
          return;
        }
        viewport = enabledElement.viewport;
      } else {
        viewport = cornerstoneViewportService.getCornerstoneViewport(
          gridViewport.id
        );
      }

      // Get number of slices
      // -> Copied from cornerstone3D jumpToSlice\_getImageSliceData()
      let numberOfSlices = 0;

      if (viewport instanceof StackViewport) {
        numberOfSlices = viewport.getImageIds().length;
      } else if (viewport instanceof VolumeViewport) {
        numberOfSlices =
          csUtils.getImageSliceDataForVolumeViewport(viewport).numberOfSlices;
      } else {
        throw new Error('Unsupported viewport type');
      }

      const jumpIndex =
        imageIndex < 0 ? numberOfSlices + imageIndex : imageIndex;
      if (jumpIndex >= numberOfSlices || jumpIndex < 0) {
        throw new Error(`Can't jump to ${imageIndex}`);
      }

      // Set slice to last slice
      const options = { imageIndex: jumpIndex };
      cstUtils.jumpToSlice(viewport.element, options);
    },
    scroll: ({ direction }) => {
      const enabledElement = _getActiveViewportEnabledElement();

      if (!enabledElement) {
        return;
      }

      const { viewport } = enabledElement;
      const options = { delta: direction };

      cstUtils.scroll(viewport, options);
    },
    setViewportColormap: ({
      viewportIndex,
      displaySetInstanceUID,
      colormap,
      immediate = false,
    }) => {
      const viewport =
        cornerstoneViewportService.getCornerstoneViewportByIndex(viewportIndex);

      const actorEntries = viewport.getActors();

      const actorEntry = actorEntries.find(actorEntry => {
        return actorEntry.uid.includes(displaySetInstanceUID);
      });

      const { actor: volumeActor, uid: volumeId } = actorEntry;

      viewport.setProperties({ colormap, volumeActor }, volumeId);

      if (immediate) {
        viewport.render();
      }
    },
    incrementActiveViewport: () => {
      const { activeViewportIndex, viewports } = viewportGridService.getState();
      const nextViewportIndex = (activeViewportIndex + 1) % viewports.length;
      console.log('dee');
      viewportGridService.setActiveViewportIndex(nextViewportIndex);
    },
    decrementActiveViewport: () => {
      const { activeViewportIndex, viewports } = viewportGridService.getState();
      const nextViewportIndex =
        (activeViewportIndex - 1 + viewports.length) % viewports.length;
      console.log('dee');
      viewportGridService.setActiveViewportIndex(nextViewportIndex);
    },
    toggleStackImageSync: ({ toggledState }) => {
      toggleStackImageSync({
        getEnabledElement,
        servicesManager,
        toggledState,
      });
    },
    toggleReferenceLines: ({ toggledState }) => {
      const { activeViewportIndex } = viewportGridService.getState();
      const viewportInfo =
        cornerstoneViewportService.getViewportInfoByIndex(activeViewportIndex);

      const viewportId = viewportInfo.getViewportId();
      const toolGroup = toolGroupService.getToolGroupForViewport(viewportId);

      if (!toggledState) {
        toolGroup.setToolDisabled(ReferenceLinesTool.toolName);
      }

      toolGroup.setToolConfiguration(
        ReferenceLinesTool.toolName,
        {
          sourceViewportId: viewportId,
        },
        true // overwrite
      );
      toolGroup.setToolEnabled(ReferenceLinesTool.toolName);
    },
  };

  const definitions = {
    // The command here is to show the viewer context menu, as being the
    // context menu
    showCornerstoneContextMenu: {
      commandFn: actions.showCornerstoneContextMenu,
      storeContexts: [],
      options: {
        menuCustomizationId: 'measurementsContextMenu',
        commands: [
          {
            commandName: 'showContextMenu',
          },
        ],
      },
    },

    getNearbyToolData: {
      commandFn: actions.getNearbyToolData,
    },
    getNearbyAnnotation: {
      commandFn: actions.getNearbyAnnotation,
      storeContexts: [],
      options: {},
    },

    deleteMeasurement: {
      commandFn: actions.deleteMeasurement,
    },
    setMeasurementLabel: {
      commandFn: actions.setMeasurementLabel,
    },
    updateMeasurement: {
      commandFn: actions.updateMeasurement,
    },
    sharpening: {
      commandFn: actions.sharpening,
      storeContexts: [],
      options: {},
    },
    ExitFusionIamge: {
      commandFn: actions.ExitFusionIamge,
      storeContexts: [],
      options: {},
    },
    setWindowLevel: {
      commandFn: actions.setWindowLevel,
    },
    setColorOverlay: {
      commandFn: actions.setColorOverlay,
    },
    setUploadAndDownload: {
      commandFn: actions.setUploadAndDownload,
    },
    setOrderFeature: {
      commandFn: actions.setOrderFeature,
    },
    toolbarServiceRecordInteraction: {
      commandFn: actions.toolbarServiceRecordInteraction,
    },
    setToolActive: {
      commandFn: actions.setToolActive,
    },
    rotateViewportCW: {
      commandFn: actions.rotateViewport,
      options: { rotation: 90 },
    },
    rotateViewportCCW: {
      commandFn: actions.rotateViewport,
      options: { rotation: -90 },
    },
    incrementActiveViewport: {
      commandFn: actions.incrementActiveViewport,
    },
    decrementActiveViewport: {
      commandFn: actions.decrementActiveViewport,
    },
    flipViewportHorizontal: {
      commandFn: actions.flipViewportHorizontal,
    },
    flipViewportVertical: {
      commandFn: actions.flipViewportVertical,
    },
    invertViewport: {
      commandFn: actions.invertViewport,
    },
    resetViewport: {
      commandFn: actions.resetViewport,
    },
    scaleUpViewport: {
      commandFn: actions.scaleViewport,
      options: { direction: 1 },
    },
    scaleDownViewport: {
      commandFn: actions.scaleViewport,
      options: { direction: -1 },
    },
    fitViewportToWindow: {
      commandFn: actions.scaleViewport,
      options: { direction: 0 },
    },
    nextImage: {
      commandFn: actions.scroll,
      options: { direction: 1 },
    },
    previousImage: {
      commandFn: actions.scroll,
      options: { direction: -1 },
    },
    firstImage: {
      commandFn: actions.jumpToImage,
      options: { imageIndex: 0 },
    },
    lastImage: {
      commandFn: actions.jumpToImage,
      options: { imageIndex: -1 },
    },
    jumpToImage: {
      commandFn: actions.jumpToImage,
    },
    showDownloadViewportModal: {
      commandFn: actions.showDownloadViewportModal,
    },
    toggleCine: {
      commandFn: actions.toggleCine,
    },
    toggleFit: {
      commandFn: actions.toggleFit,
    },
    set3DVolume: {
      commandFn: actions.set3DVolume,
    },
    handleScaleClick: {
      commandFn: actions.handleScaleClick,
    },
    radiographicPrinting: {
      commandFn: actions.radiographicPrinting,
    },
    setMPRChange: {
      commandFn: actions.setMPRChange,
    },
    arrowTextCallback: {
      commandFn: actions.arrowTextCallback,
    },
    setViewportActive: {
      commandFn: actions.setViewportActive,
    },
    setViewportColormap: {
      commandFn: actions.setViewportColormap,
    },
    toggleStackImageSync: {
      commandFn: actions.toggleStackImageSync,
    },
    toggleReferenceLines: {
      commandFn: actions.toggleReferenceLines,
    },
  };

  return {
    actions,
    definitions,
    defaultContext: 'CORNERSTONE',
  };
}

export default commandsModule;
