import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDrag } from 'react-dnd';
import { Icon } from '../';
import { StringNumber } from '../../types';
import DisplaySetMessageListTooltip from '../DisplaySetMessageListTooltip';
import * as cornerstone from '@cornerstonejs/core';
import {Enums, eventTarget ,cache, getEnabledElements} from "@cornerstonejs/core";
import {servicesManager} from "@ohif/viewer/src/App";

/**
 * Display a thumbnail for a display set.
 */
const Thumbnail = ({
  displaySetInstanceUID,
  className,
  imageSrc,
  imageAltText,
  description,
  seriesNumber,
  numInstances,
  messages,
  countIcon,
  dragData,
  isActive,
  onClick,
  onDoubleClick,
}): React.ReactNode => {
  const { displaySetService,segmentationService } = servicesManager.services;
  // TODO: We should wrap our thumbnail to create a "DraggableThumbnail", as
  // this will still allow for "drag", even if there is no drop target for the
  // specified item.
  const [collectedProps, drag, dragPreview] = useDrag({
    type: 'displayset',
    item: { ...dragData },
    canDrag: function(monitor) {
      return Object.keys(dragData).length !== 0;
    },
  });
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  const [processingProgress, setProcessingProgress] = useState({
    percentComplete: null,
    totalSegments: null,
  });


  useEffect(() => {
    const referencedDisplaySet = displaySetService.getDisplaySetByUID(
        displaySetInstanceUID
    );
    let Elements = getEnabledElements()
    if (Elements.length>0){

    }
    const seriesMetadata = cornerstone.metaData.get('series', displaySetInstanceUID);
    const totalImages = seriesMetadata ? seriesMetadata['images'] : 0;
  })


  return (
    <div>
      {window.layoutFlag ? (
        <div
          className={classnames(
            className,
            'flex flex-col flex-1  cursor-pointer outline-none select-none group'
          )}
          id={`thumbnail-${displaySetInstanceUID}`}
          style={{ width: '150px', height: '93px', margin: '4px 2px' }}
          data-cy={`study-browser-thumbnail`}
          // onClick={onClick}
          onClick={onDoubleClick}
          role="button"
          tabIndex="0"
        >
          <div ref={drag} style={{ height: '93px'}}>
            <div
              className={classnames(
                `flex flex-1 items-center justify-center bg-black text-base text-white overflow-hidden`,
                isActive ? 'border-2' : 'border border-secondary-light'
              )}
              style={{
                height: '93px',
                // margin: isActive ? '0' : '1px',
                backgroundColor: isActive
                  ? '#e1e1e1'
                  : 'rgb(77 75 79)',
                borderRadius: '2px',
                borderWidth: '0px',
              }}
            >
              {imageSrc ? (
                <img
                  src={imageSrc}
                  alt={imageAltText}
                  style={{ width: '133px' }}
                />
              ) : (
                <div>{imageAltText}</div>
              )}
            </div>
            <div
              className="flex flex-row items-center flex-1 pt-2 text-base"
              style={{
                fontSize: '12px',
                position: 'relative',
                top: '-88px',
                left: '10px',
                height:'25px',
                fontWeight: '600',
                color: '#e1e1e1',
              }}
            >
              <div style={{position:'absolute',right:0,marginRight:'20px'}}>
                <DisplaySetMessageListTooltip
                    messages={messages}
                    id={`display-set-tooltip-${displaySetInstanceUID}`}
                />
              </div>
              {` ${description}`}
            </div>
            <div
              className="flex flex-row items-center flex-1 pt-2 text-base"
              style={{
                fontSize: '12px',
                position: 'relative',
                top: '-62px',
                left: '10px',
                fontWeight: '500',
              }}
            >
              <div className="mr-1">
                {/*<span className="font-bold text-common-light">{'序列:'}</span>*/}
                <span style={{ fontWeight: '500' }}>{seriesNumber}</span>
              </div>
            </div>
            <span
              className="flex flex-row items-center flex-1 pt-2 text-base"
              style={{ position: 'relative', top: '-88px',
                width:'35px',
                left: numInstances < 10 ? 120 : numInstances < 100 ? 112 : 104 + 'px' }}
            >
              {/* <Icon name={countIcon || 'group-layers'} className="w-3 mr-2" /> */}
              <span style={{ fontWeight: '500' }}>{` ${numInstances}`}</span>张
            </span>
          </div>
        </div>
      ) : (
        <div
          className={classnames(
            className,
            'flex flex-col flex-1  cursor-pointer outline-none select-none group'
          )}
          id={`thumbnail-${displaySetInstanceUID}`}
          style={{ width: '143px', height: '90px', margin: '4px 2px 4px 2px' }}
          data-cy={`study-browser-thumbnail`}
          // onClick={onClick}
          onClick={onDoubleClick}
          role="button"
          tabIndex="0"
        >
          <div ref={drag} style={{ height: '93px'}}>
            <div
              className={classnames(
                `flex flex-1 items-center justify-center bg-black text-base text-white overflow-hidden`,
                isActive ? 'border-2' : 'border border-secondary-light'
              )}
              style={{
                // margin: isActive ? '0' : '1px',
                height: '93px',
                //borderColor: isActive ? '#e2e8f0' : '#000',
                backgroundColor: isActive
                  ? '#e1e1e1'
                  : 'rgb(77 75 79)',
                borderRadius: '2px',
                borderWidth: '0px',
              }}
            >
              {imageSrc ? (
                <img
                  src={imageSrc}
                  alt={imageAltText}
                  style={{ width: '133px' }}
                />
              ) : (
                <div>{imageAltText}</div>
              )}
            </div>
            <div
              className="flex flex-row items-center flex-1 pt-2 text-base"
              style={{
                fontSize: '12px',
                position: 'relative',
                top: '-87px',
                left: '7px',
                fontWeight: '600',
                height:'25px',
                color: '#e1e1e1',
              }}
            >
              <div style={{position:'absolute',right:0,marginRight:'20px'}}>
                <DisplaySetMessageListTooltip
                    messages={messages}
                    id={`display-set-tooltip-${displaySetInstanceUID}`}
                />
              </div>
              {` ${description}`}
            </div>
            <div
              className="flex flex-row items-center flex-1 pt-2 text-base"
              style={{
                fontSize: '12px',
                position: 'relative',
                top: '-63px',
                left: '8px',
                fontWeight: '500',
              }}
            >
              <div className="mr-1">
                {/*<span className="font-bold text-common-light">{'序列:'}</span>*/}
                <span style={{ fontWeight: '500' }}>{seriesNumber}</span>

              </div>
            </div>
            <span
              className="flex flex-row items-center flex-1 pt-2 text-base"
              style={{
                fontSize: '12px',
                position: 'relative',
                top: '-90px',
                width:'36px',
                left: numInstances < 10 ? 116 : numInstances < 100 ? 110 : 104 + 'px',
              }}
            >
              {/* <Icon name={countIcon || 'group-layers'} className="w-3 mr-2" /> */}
              <span style={{ fontWeight: '500' }}>{` ${numInstances}`}张</span>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

Thumbnail.propTypes = {
  displaySetInstanceUID: PropTypes.string.isRequired,
  className: PropTypes.string,
  imageSrc: PropTypes.string,
  dragData: PropTypes.shape({
    /** Must match the "type" a dropTarget expects */
    type: PropTypes.string.isRequired,
  }),
  imageAltText: PropTypes.string,
  description: PropTypes.string.isRequired,
  seriesNumber: StringNumber.isRequired,
  numInstances: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
};

Thumbnail.defaultProps = {
  dragData: {},
};

export default Thumbnail;
