import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Icon } from '@ohif/ui';
import ProgressLoadingBar from '../ProgressLoadingBar';

/**
 *  A React component that renders a loading indicator.
 * if progress is not provided, it will render an infinite loading indicator
 * if progress is provided, it will render a progress bar
 * Optionally a textBlock can be provided to display a message
 */
function LoadingIndicatorProgress({
  className,
  textBlock,
  progress,
  showLoadingIndicator,
}) {
  const [delayedContent, setDelayedContent] = useState(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // if (showLoadingIndicator) {
      //   setDelayedContent(
      //     <div style={{ color: 'white', textAlign: 'center' }}>
      //       影像数据不存在
      //     </div>
      //   );
      // } else {
      //   setDelayedContent(<ProgressLoadingBar progress={progress} />);
      // }
    }, 1000); // 设置延迟时间（单位：毫秒）

    return () => clearTimeout(timeout); // 清除定时器
  }, [showLoadingIndicator, progress]);

  return (
    <div
      className={classNames(
        'absolute z-50 top-0 left-0 flex flex-col items-center justify-center space-y-5',
        className
      )}
    >
      <Icon name="loading-ohif-mark" className="text-white w-12 h-12" />
      <div style={{width:'150px'}}> <ProgressLoadingBar progress={progress} /></div>
      <div className="w-48">{delayedContent}</div>
      {textBlock}
    </div>
  );
}

export default LoadingIndicatorProgress;
