import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.css';
import {Modal, Radio,Input,InputNumber } from "antd";
import {utilities as csUtils} from "@cornerstonejs/core";
import {
    getEnabledElements,
} from '@cornerstonejs/core';

const WindowLevelMenuItem = ({title, subtitle, isSelected, index}) => (
    <>
        {title == '自定义' ? (
            <div className={classNames(
                'flex flex-row items-center p-3 h-8 w-full bg-primary-dark',
                isSelected && 'bg-primary-dark'
            )}  onClick={() => {
                let input1 = 500
                let input2 = 50
                const numberBlur1 = e => {
                    input1 = e
                };
                const numberBlur2 = e => {
                    input2 = e
                };
                const openModal = () => {
                    Modal.confirm({
                        title: '自定义窗宽/窗位设置',
                        content: (
                            <div style={{ marginTop: '20px', height: '25vh' }}>
                                <p
                                    style={{
                                        marginTop: '20px',
                                        marginBottom: '20px',
                                        fontSize: '15px',
                                        fontWeight: '600',
                                    }}
                                >
                                    窗宽
                                </p>
                                <InputNumber decimalSeparator={0} min={-100000} max={100000} onChange={numberBlur1} defaultValue={500} style={{width:'200px'}}/>
                                <p
                                    style={{
                                        marginTop: '20px',
                                        marginBottom: '20px',
                                        fontSize: '15px',
                                        fontWeight: '600',
                                    }}
                                >
                                    窗位
                                </p>
                                <InputNumber decimalSeparator={0} min={-100000} max={100000} onChange={numberBlur2} defaultValue={50}  style={{width:'200px'}}/>
                            </div>
                        ),
                        closable: true,
                        okText: '确定切换', // 更改确定按钮的文本
                        cancelText: '取消切换', // 更改确定按钮的文本
                        icon: null, // 禁用默认图标
                        width: '25%', // 自定义宽度
                        okButtonProps: {
                            className: 'custom-ok-button', // 自定义类名
                            style: { background: 'blue', borderColor: 'blue' }, // 自定义样式
                        },
                        onOk() {
                            console.log(input1);
                            // convert to numbers
                            const windowWidthNum = Number(input1);
                            const windowCenterNum = Number(input2);

                            const Elements = getEnabledElements();
                            Elements.forEach((element, index) => {
                                if (Elements.length==10&&(index==3||index==4||index==5||index==9)){
                                    return
                                }
                                let viewport = element.viewport
                                const { lower, upper } = csUtils.windowLevel.toLowHighRange(
                                    windowWidthNum,
                                    windowCenterNum
                                );
                                viewport.setProperties({
                                    voiRange: {
                                        upper,
                                        lower,
                                    },
                                });
                                window.windowLevelData = {upper:upper,lower:lower}
                                viewport.render();
                            })
                        },
                    });
                };
                openModal();
            }}>
                <span className=" mr-2 text-base whitespace-nowrap">{title}</span>
                <span className="flex-1  font-light text-sm whitespace-nowrap">{subtitle}</span>
                <span className=" ml-5 text-sm whitespace-nowrap">{index + 1}</span>
            </div>
        ) : (
            <div className={classNames(
                    'flex flex-row items-center p-3 h-8 w-full bg-primary-dark',
                    isSelected && 'bg-primary-dark'
                )}>
                <span className=" mr-2 text-base whitespace-nowrap">{title}</span>
                <span className="flex-1  font-light text-sm whitespace-nowrap">{subtitle}</span>
                <span className=" ml-5 text-sm whitespace-nowrap">{index + 1}</span>
            </div>
        )}

    </>
);

WindowLevelMenuItem.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
};

export default WindowLevelMenuItem;
