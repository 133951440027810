import { metaData } from '@cornerstonejs/core';
import { PlanarFreehandROITool, utilities } from '@cornerstonejs/tools';
import callInputDialog from '../utils/callInputDialog';
import getActiveViewportEnabledElement from '../utils/getActiveViewportEnabledElement';

const { calibrateImageSpacing } = utilities;

let startX = 0;
let startY = 0;
let currentX = 0;
let currentY = 0;
let angle = 0;
let isDragging = false;
let newViewport;
/**
 * Calibration Line tool works almost the same as the
 */
class FreehandlineTool extends PlanarFreehandROITool {
  static toolName = 'Freehandline';

  _renderingViewport: any;

  _lengthToolRenderAnnotation = this.renderAnnotation;

  renderAnnotation = (enabledElement, svgDrawingHelper) => {

    let { viewport } = enabledElement;
    newViewport  = {...enabledElement};
    this._renderingViewport = viewport;

    return this._lengthToolRenderAnnotation(newViewport, svgDrawingHelper);
  };

  _getTextLines(data, targetId) {
    const [canvasPoint1, canvasPoint2] = data.handles.points.map(p =>
      this._renderingViewport.worldToCanvas(p)
    );
    const lengthPx =
      Math.round(calculateLength2(canvasPoint1, canvasPoint2) * 100) / 100;
    // 获取图像数据
    // let canvas = newViewport.viewport.canvas;
    // // let ctx = canvas.getContext('2d');
    // canvas.getContext('2d').strokeStyle = 'rgb(0, 220, 0)';
    // canvas.getContext('2d').lineWidth = 1;
    // let isDrawing = false;
    // let lastX, lastY;
    //
    // canvas.addEventListener('mousedown', function(event) {
    //   isDrawing = true;
    //   canvas.getContext('2d').strokeStyle = 'rgb(0, 220, 0)';
    //   canvas.getContext('2d').lineWidth = 1;
    //   const rect = canvas.getBoundingClientRect();
    //   lastX = event.clientX - rect.left;
    //   lastY = event.clientY - rect.top;
    // });
    // canvas.addEventListener('mousemove', function(event) {
    //   if (isDrawing) {
    //     const rect = canvas.getBoundingClientRect();
    //     let currentX = event.clientX - rect.left;
    //     let currentY = event.clientY - rect.top;
    //     canvas.getContext('2d').beginPath();
    //     canvas.getContext('2d').moveTo(lastX, lastY);
    //     canvas.getContext('2d').lineTo(currentX, currentY);
    //     canvas.getContext('2d').stroke();
    //     lastX = currentX;
    //     lastY = currentY;
    //   } else {
    //     isDrawing = false;
    //   }
    // });
    // canvas.addEventListener('mouseup', function(event) {
    //   isDrawing = false;
    // });
    // newViewport.viewport.canvas =  canvas
    // console.log( this._lengthToolRenderAnnotation(newViewport, svgDrawingHelper),"newViewportnewViewportnewViewport")
    const textLines = [`${'1'}px`];

    return textLines;
  }
}

function calculateLength2(point1, point2) {
  const dx = point1[0] - point2[0];
  const dy = point1[1] - point2[1];
  return Math.sqrt(dx * dx + dy * dy);
}

function calculateLength3(pos1, pos2) {
  const dx = pos1[0] - pos2[0];
  const dy = pos1[1] - pos2[1];
  const dz = pos1[2] - pos2[2];

  return Math.sqrt(dx * dx + dy * dy + dz * dz);
}

export default FreehandlineTool;

export function onCompletedFreehandline(servicesManager, csToolsEvent) {
  const { uiDialogService, viewportGridService } = servicesManager.services;


  // calculate length (mm) with the current Pixel Spacing
  const annotationAddedEventDetail = csToolsEvent.detail;
  const {
    annotation: { metadata, data: annotationData },
  } = annotationAddedEventDetail;
  const { referencedImageId: imageId } = metadata;
  const enabledElement = getActiveViewportEnabledElement(viewportGridService);
  const { viewport } = enabledElement;

  const length =
    Math.round(
      calculateLength3(
        annotationData.handles.points[0],
        annotationData.handles.points[1]
      ) * 100
    ) / 100;

  // calculate the currently applied pixel spacing on the viewport
  const calibratedPixelSpacing = metaData.get(
    'calibratedPixelSpacing',
    imageId
  );
  const imagePlaneModule = metaData.get('imagePlaneModule', imageId);
  const currentRowPixelSpacing =
    calibratedPixelSpacing?.[0] || imagePlaneModule?.rowPixelSpacing || 1;
  const currentColumnPixelSpacing =
    calibratedPixelSpacing?.[1] || imagePlaneModule?.columnPixelSpacing || 1;

  const adjustCalibration = newLength => {
    const spacingScale = newLength / length;
    const rowSpacing = spacingScale * currentRowPixelSpacing;
    const colSpacing = spacingScale * currentColumnPixelSpacing;

    // trigger resize of the viewport to adjust the world/pixel mapping
    calibrateImageSpacing(
      imageId,
      viewport.getRenderingEngine(),
      rowSpacing,
      colSpacing
    );
  };

  return new Promise((resolve, reject) => {
    if (!uiDialogService) {
      reject('UIDialogService is not initiated');
      return;
    }

    callInputDialog(
      uiDialogService,
      {
        text: '',
        label: `${length}`,
      },
      (value, id) => {
        if (id === 'save') {
          adjustCalibration(Number.parseFloat(value));
          resolve(true);
        } else {
          reject('cancel');
        }
      },
      false,
      {
        dialogTitle: 'Calibration',
        inputLabel: 'Actual Physical distance (mm)',

        // the input value must be a number
        validateFunc: val => {
          try {
            const v = Number.parseFloat(val);
            return !isNaN(v) && v !== 0.0;
          } catch {
            return false;
          }
        },
      }
    );
  });
}
