import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

import { Icon } from '../';

const bg323232 = {
  backgroundColor: 'rgb(68 68 67)',
  marginTop: '2px',
};

const baseClasses =
  'first:border-0 border-t border-secondary-light cursor-pointer select-none outline-none';

const StudyItem = ({
  date,
  description,
  numInstances,
  modalities,
  trackedSeries,
  isActive,
  onClick,
}) => {
  const formattedDate = date ? moment(date).format('YYYY-MM-DD') : null;
  return (
      <React.Fragment>
        {!window.layoutFlag ? (
            <div
                className={classnames(
                    isActive ? 'bg-secondary-dark' : 'bg-black hover:bg-secondary-main',
                    baseClasses
                )}
                style={bg323232}
                onClick={onClick}
                onKeyDown={onClick}
                role="button"
                tabIndex="0"
            >
              <div className="flex flex-col flex-1 px-1 pb-1 pt-1">
                <div className="flex flex-row items-center justify-between pt-2 pb-2">
                  <div className="text-base" style={{marginLeft:'10px'}}>{modalities}</div>
                  <div className="flex flex-row items-center text-base" style={{paddingRight:'8px'}}>
                    <Icon name="group-layers" className="w-4 mx-2" />
                    {numInstances}
                  </div>
                </div>
                {/* <div className="flex flex-row py-1">
          <div className="pr-5">{modalities}</div>
          <div className="text-base  break-words truncate-2-lines">
            {description}
          </div>
        </div> */}
              </div>
              {!!trackedSeries && (
                  <div className="flex flex-2">
                    <div
                        className={classnames(
                            'flex flex-row text-base text-white py-1 pl-2 pr-4 mt-2 ',
                            isActive
                                ? 'flex-1 border-t border-secondary-light justify-center'
                                : 'rounded-sm mx-4 mb-4'
                        )}
                    >
                      <Icon name="tracked" className="w-4 mr-2 text-primary-light" />
                      {trackedSeries} 跟踪系列
                    </div>
                  </div>
              )}
            </div>
        ):(
            <div
                className={classnames(
                    isActive ? 'bg-secondary-dark' : 'bg-black hover:bg-secondary-main',
                    baseClasses
                )}
                style={{ backgroundColor: 'rgb(37, 37, 37)',
                  marginTop: '2px',position:"fixed",height:'62px',width:'86px',bottom:0}}
                onClick={onClick}
                onKeyDown={onClick}
                role="button"
                tabIndex="0"
            >
              <div className="flex flex-col flex-1 px-1 pb-1 pt-1">
                <div className="">
                  <div className="text-base" style={{marginTop:'2px'}}>{modalities}</div>
                  <div className="flex flex-row items-center text-base" style={{marginLeft:'-6px',paddingRight:'8px'}}>
                    <Icon name="group-layers" className="w-4 mx-2" />
                    {numInstances}
                  </div>
                </div>
              </div>
              {!!trackedSeries && (
                  <div className="flex flex-2">
                    <div
                        className={classnames(
                            'flex flex-row text-base text-white py-1 pl-2 pr-4 mt-2 ',
                            isActive
                                ? 'flex-1 border-t border-secondary-light justify-center'
                                : 'rounded-sm mx-4 mb-4'
                        )}
                    >
                      <Icon name="tracked" className="w-4 mr-2 text-primary-light" />
                      {trackedSeries} 跟踪系列
                    </div>
                  </div>
              )}
            </div>
        )}
      </React.Fragment>




  );
};

StudyItem.propTypes = {
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  modalities: PropTypes.string.isRequired,
  numInstances: PropTypes.number.isRequired,
  trackedSeries: PropTypes.number,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default StudyItem;
